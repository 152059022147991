
.SearchParent {
  min-height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 35px;
}

.SearchContainer {
  margin-top: 7px;
  background-color: #d6d7d5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  height: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.SearchIcon {
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}