.DateSelector{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #fff;
}

.DateContainer{
    border: 2px solid #fff;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 1rem;
}

.DateContainerDark{
    border: 2px solid #d1d1d1;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 1rem;
}

.DateValue{
    font-size: 1.1rem;
    padding: 5px 15px;
    color: #fafafa;
}

.DateValueDark{
    font-size: 1.1rem;
    padding: 5px 15px;
    color: #d1d1d1;
}

.TopSection{
    background-color: rgb(0,142,223);
    position: fixed;
    width: 100%;
}

.TopSectionDark{
    background-color: #06599f;
    position: fixed;
    width: 100%;
}

.SunUpIcon, .SunDownIcon{
    height: 40px;
}

.SunTimeSection{
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.WeatherIcon{
    height: 40px;
}

.TableWeather{
    width: 100%;
    border: none;
}

.TableWeather thead{
    background-color: rgb(0,142,223);
    color: #fafafa;
    font-weight: 400;
}

.TableWeather th{
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    color: #fafafa;
}

.TableWeather td{
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #EEEEEE;
    font-weight: 300;
    color: rgb(0,142,223);
}

.TableWeather tbody tr td:nth-child(2), .TableWeather tbody tr td:nth-child(4){
    background-color: transparent;
}

.TableWeather tbody tr td:nth-child(1), .TableWeather thead tr td:nth-child(1){
    width: 20%;
}

.TableWeather tbody tr td:nth-child(2), .TableWeather thead tr td:nth-child(2){
    width: 15%;
}

.TableWeather tbody tr td:nth-child(3), .TableWeather thead tr td:nth-child(3){
    width: 15%;
}

.TableWeather tbody tr td:nth-child(4), .TableWeather thead tr td:nth-child(4){
    width: 15%;
}

.TableWeather tbody tr td:nth-child(5), .TableWeather thead tr td:nth-child(5){
    width: 20%;
}

.TableWeatherDark{
    width: 100%;
    border: none;
}

.TableWeatherDark thead{
    background-color: #06599f;
    color: #d1d1d1;
    font-weight: 400;
}

.TableWeatherDark th{
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    color: #d1d1d1;
}

.TableWeatherDark td{
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #424242;
    font-weight: 300;
    color: #d1d1d1;
}

.TableWeatherDark tbody tr td:nth-child(2), .TableWeatherDark tbody tr td:nth-child(4){
    background-color: transparent;
}

.TableWeatherDark tbody tr td:nth-child(1), .TableWeatherDark thead tr td:nth-child(1){
    width: 20%;
}

.TableWeatherDark tbody tr td:nth-child(2), .TableWeatherDark thead tr td:nth-child(2){
    width: 15%;
}

.TableWeatherDark tbody tr td:nth-child(3), .TableWeatherDark thead tr td:nth-child(3){
    width: 15%;
}

.TableWeatherDark tbody tr td:nth-child(4), .TableWeatherDark thead tr td:nth-child(4){
    width: 15%;
}

.TableWeatherDark tbody tr td:nth-child(5), .TableWeatherDark thead tr td:nth-child(5){
    width: 20%;
}

.TextLight{
    color: #fafafa;
}

.TextDark{
    color: #d1d1d1;
}