.WetterTVKachelParent {
  background-color: #fff;
  padding: 10px 20px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: .5rem;
}

.HeadSection {
  margin-bottom: 1.5rem;
}

.BreadcrubsLink {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
}

.BreadcrubsLink:hover,
.BreadcrubsLink:focus,
.BreadcrubsLink:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.BreadcrubsNonLink {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.VideoHead{
    font-size: 3rem;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 300;
}

.VideoDetails{
    font-size: 1rem;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 500;
}

.VideoLink{
    text-decoration: none !important;
}


.WetterTVKachelParent .WetterTVContainer .WetterTVItem .WetterImage {
  height: 225px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVItem .WetterImage img {
  width: 50px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem {
  flex-grow: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-bottom: 10px;
  transition: 0.2s ease-in;
  border-radius: 3px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
}

/*.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:first-of-type{
    margin-right: 5px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:last-of-type{
    margin-left: 5px;
}*/

.WetterTVKachelParent .WetterTVContainer .WetterTVItem .WetterData {
  padding: 5px 10px;
}

.WetterTVContainer .WetterTVItem .WetterDataDate {
  color: #757575;
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  line-height: 0.5;
  margin-top: 7px;
}

.WetterTVContainer .WetterTVItem .WetterDataTitle {
  font-size: 1.1rem;
  font-family: "Source Sans Pro", sans-serif !important;
  color: rgba(0, 62, 133, 0.6);
  line-height: 1;
  margin-top: 1rem;
}

.WetterTVContainer .WetterTVItem .WetterDataBody {
  font-size: 0.8rem;
  margin-top: 10px;
  color: rgb(84,84,84);
  font-family: "Source Sans Pro", sans-serif !important;
}

.AuchInteressant{

margin-top: 3rem;

}