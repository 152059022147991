.LocationSearchResultParent {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: .5rem;
}

.HeadSection {
    margin-bottom: 1.5rem;
}

.BreadcrubsLink {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
}

.BreadcrubsLink:hover,
.BreadcrubsLink:focus,
.BreadcrubsLink:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
}

.BreadcrubsNonLink {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
}

.PageHead{
    font-size: 2.2rem;
    font-weight: 300;
    font-family: "Source Sans Pro", sans-serif !important;
    color: rgba(0, 62, 133, 0.6) ;
}

.PageSubHead{
    font-size: 1.1rem;
    font-weight: 300;
    font-family: "Source Sans Pro", sans-serif !important;
    /*color: rgba(84, 84, 84, 1) ;*/
}

.LocationListItem{
    padding: 10px 0px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 1.2rem;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 300;
    transition: 0.5s;
    transition: transform 0.3s ease;
    transform: translateX(0px);
}

.LocationListItem:hover {
    color: rgb(0, 108, 189);
    transform: translateX(10px);
}

.ListSection{
    margin-top: 1rem;
}