@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Exo 2', sans-serif !important;
  background-color: #fff;
  color: rgb(84,84,84);
  outline: none;
  background-color: #FAFAFA;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a{
  text-decoration: none !important;
}

.boxStyle{
  background-color: #ffffff;
  border-radius: 3px;
  border: 0px solid #EEEEEE;
  margin-top: 10px;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleDark{
  background-color: #252525;
  border-radius: 3px;
  border: 0px solid #252525;
  margin-top: 10px;
}

.boxStyleWithPadding{
  background-color: #ffffff;
  border-radius: 3px;
  border: 0px solid #EEEEEE;
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleWithPadding-dark{
  background-color: #252525;
  border-radius: 3px;
  border: 0px solid #252525;
  margin-top: 10px;
  padding: 10px;
}

.boxStyleWithPaddingSpecial{
  background-color: #ef5350;
  border-radius: 3px;
  border: 1px solid #ef5350;
  padding: 0px;
  /*box-shadow: rgba(60, 64, 67, 0.04) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;*/
  color: #fff;
}

.boxStyleWithPaddingSpecial-dark{
  background-color: #ef5350;
  border-radius: 3px;
  border: 1px solid #ef5350;
  color: #3eb8ff;
  padding: 0px;
  color: #fff;
}

.boxStyleWithPaddingSpecial-dark .MuiTypography-colorTextSecondary{
  color: #fff !important;
}

.boxStyleWithPaddingSpecial .warningParent .MuiList-padding{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial .warningParent .MuiListItem-dense{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial .warningParent .MuiListItem-gutters{
  padding-left: 10px;
  padding-right: 10px;
}

.boxStyleWithPaddingSpecial .warningParent .MuiAvatar-colorDefault{
  background-color: rgba(245,245,245 ,1);
}

.boxStyleWithPaddingSpecial .warningParent .MuiListItemText-secondary{
  font-weight: 300;
  color: #fff !important;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiList-padding{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiListItem-dense{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiListItem-gutters{
  padding-left: 10px;
  padding-right: 10px;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiAvatar-colorDefault{
  background-color: rgba(245,245,245 ,1);
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiListItemText-secondary{
  color: rgba(245,245,245 ,1);
  font-weight: 400;
}

.warningContainer .MuiBox-root{
  padding: 0px 10px 0px;
}

.boxStyleWithPaddingSecondary{
  background-color: rgb(0,142,223);
  border-radius: 3px;
  border: 0px solid rgb(0,142,223);
  margin-top: 0px;
  padding: 10px;
  color: #fafafa;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleWithPaddingSecondaryDark{
  background-color: #06599f;
  border-radius: 3px;
  border: 0px solid #06599f;
  margin-top: 0px;
  padding: 10px;
  color: #d1d1d1;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleP-NMT{
  background-color: #ffffff;
  border-radius: 3px;
  border: 0px solid #EEEEEE;
  margin-top: 0px;
  padding: 10px;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleP-NMT-Dark{
  background-color: #252525;
  border-radius: 3px;
  border: 0px solid #252525;
  margin-top: 0px;
  padding: 10px;
}

.invert
{
  background-color: rgb(0,142,223);
  border: 1px solid rgb(0,142,223);
  color: #ffffff;
}

.invertDark
{
  background-color: #06599f;
  border: 1px solid #06599f;
  color: #ffffff;
}

.rec-dot
{
    background-color: rgba(84,84,84, .4) !important;
    height: 5px !important;
    width: 5px !important;
    box-shadow: none !important;
    margin-top: 0px !important;
}

.boxStyleP-NMT-Dark .rec-dot
{
    background-color: rgba(107, 107, 107, 0.4) !important;
    height: 5px !important;
    width: 5px !important;
    box-shadow: none !important;
    margin-top: 0px !important;
}

.boxStyleP-NMT-Dark .rec-dot_active
{
  background-color: #06599f !important;
  height: 8px !important;
  width: 8px !important;
}

.rec-dot_active
{
  background-color: rgba(0,142,223, 1) !important;
  height: 8px !important;
  width: 8px !important;
}

.invert .rec-dot{
  background-color: rgba(255,255,255, .3) !important;
}

.invert .rec-dot_active{
  background-color: #ffffff !important;
}

.rec-pagination
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 10px;
  margin-top: 5px !important;
}

.rec-slider-container{
  margin: 0 !important;
}

.dialog-confirm-btn
{
  background-color: rgb(0,62,133);
  color: #fff;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
  outline: none !important;
}

.dialog-confirm-btn-dark
{
  background-color: #06599f;
  border-color: #06599f !important;
  color: #fff;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px inset, rgb(165 202 234) 0px 0px 0px !important;
  outline: none !important;
}

.MuiTab-textColorPrimary.Mui-selected{
    background-color: rgb(0,142,223);
    color: #fafafa !important;
}

.darkHead{
  background-color: #1e1e1e;
}

.darkHead .MuiTab-textColorPrimary{
  color: #d1d1d1 !important;
}

.darkHead .MuiTab-textColorPrimary.Mui-selected{
    background-color: #06599f;
    color: #d1d1d1 !important;
}

#titlesvgshadow {
  text-shadow: 0px 0px 1px #fff;
}

#titlesvgshadowDark {
  text-shadow: 0px 0px 0px #616161;
}

.leaflet-control-layers-expanded{
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: .8rem;
  font-weight: normal;
  }
  

  .VierzehnTagesprognodeChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:last-child,
  .VierzehnTagesprognodeChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:nth-last-child(2),
  .TagesverlaufChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:last-child,
  .TagesverlaufChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:nth-last-child(2){
    display: none;
  }

  .NoPage{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
  }
  .boxStyleWithPadding-dark .htmlParser p,
  .boxStyleWithPadding-dark .htmlParser h1,
  .boxStyleWithPadding-dark .htmlParser h2,
  .boxStyleWithPadding-dark .htmlParser h3,
  .boxStyleWithPadding-dark .htmlParser h4,
  .boxStyleWithPadding-dark .htmlParser h5,
  .boxStyleWithPadding-dark .htmlParser h6,
  .boxStyleWithPadding-dark .htmlParser ul,
  .boxStyleWithPadding-dark .htmlParser ol{
    color: #d1d1d1 !important;
  }

  .boxStyleWithPadding-dark .htmlParser a,
  .boxStyleWithPadding-dark .htmlParser a span{
    color:rgba(6,89,159,1) !important;
  }

  .boxStyleWithPadding-dark .htmlParser span{
    color: #d1d1d1 !important;
    background-color: transparent !important;
  }

  .boxStyleWithPadding-dark .htmlParser figure.table{
    color: #d1d1d1 !important;
    background-color: transparent !important;
  }

  .htmlParser figure table{
    border-collapse: collapse !important;
  }

  .htmlParser figure table,
  .htmlParser figure.table td,
  .htmlParser figure.table th{
    border: 1px solid #ccc !important;
    padding: 5px;
  }

  .htmlParser figure.table{
    overflow-x: auto;
    margin-left: 0px;
    margin-right: 0px;
  }

.Navigation_DrawerContainer__3mt3d {
  position: relative;
}

.Navigation_MenuHeaderParent__1ym1b,
.Navigation_MenuParent__IsvJL {
  min-height: 25vh;
  min-width: 75vw;
  padding: 10px;
}

.Navigation_MenuActive__1X8iN{
    background-color: rgb(0, 142, 223);
}

.Navigation_MenuHeader__3PvRd {
  background: rgb(0, 142, 223);
  background: linear-gradient(
    170deg,
    rgba(0, 142, 223, 1) 0%,
    rgba(0, 173, 223, 1) 50%,
    rgba(0, 142, 223, 1) 100%
  );
  min-height: 100%;
  border-radius: 10px;
  color: #fff;
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin: 0px 10px;
}

.Navigation_MenuHeaderDark__3TAkp {
  background: #252525;
  min-height: 100%;
  border-radius: 10px;
  color: #d1d1d1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin: 0px 10px;
}

.Navigation_MenuFooter__23TiR {
  color: #fff;
  background-color: rgb(84, 84, 84);
  /*position: absolute;*/
  min-width: 75vw;
  width: auto;
  /*bottom: 0;*/
  text-align: center;
  padding: 8px 10px 10px 10px;
  font-size: 0.9rem;
}

.Navigation_CustomTabControl__3RWh_ {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  background-color: #FFFFFF;
}

.Navigation_CustomTabControl__3RWh_ .Navigation_CustomTabItem__2YTaA {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px 0px;
  color: rgb(84, 84, 84);
  text-decoration: none;
  text-align: center;
}

.Navigation_CustomTabControl__3RWh_ .Navigation_CustomTabItemActive__2Y1Z3 {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgb(0, 62, 133);
  color: #fff;
  padding: 5px 0px;
}

.Navigation_CustomTabControlDark__2VZze {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  background-color: #252525;
}

.Navigation_CustomTabControlDark__2VZze .Navigation_CustomTabItem__2YTaA {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px 0px;
  color: #d1d1d1;
  text-decoration: none;
  text-align: center;
}

.Navigation_CustomTabControlDark__2VZze .Navigation_CustomTabItemActive__2Y1Z3 {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #0c2140;
  color: #fff;
  padding: 5px 0px;
}

.Navigation_CustomTabItemCenter__1FyP2 {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: rgb(0, 62, 133);
  position: relative;
}

.Navigation_CustomTabItemCenter__1FyP2 .Navigation_MainCircle__3nBhn{
  position: absolute;
  bottom: 10px;
}

.Navigation_NavLink___ukT0{
    text-decoration: none;
    color: inherit;
}

.Navigation_TabImage__3T7tn{
  height: 20px;
  margin-top: 5px;
}

.Navigation_TabImageHome__2Qgwx{
  height:60px;
}

.Navigation_LoadingParent__3HvSJ{
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
  align-content: center;
  height: 100%;
}

.Navigation_Loader__2Z3E9 {
  border: 10px solid #EEEEEE;
  border-radius: 50%;
  border-top: 10px solid rgb(0,62,133);
  border-bottom: 10px solid rgb(0,62,133);
  width: 75px;
  height: 75px;
  -webkit-animation: Navigation_spin__3AUVt 2s linear infinite;
  animation: Navigation_spin__3AUVt 1s linear infinite;
  z-index:999
}

.Navigation_MenuIcons__2w4YP{
  height: 20px;
}

@-webkit-keyframes Navigation_spin__3AUVt {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Navigation_spin__3AUVt {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Navigation_ErsteLadung__3CBR8{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
}

.Navigation_LinearActivity__1KGF6 {
  overflow: hidden;
  width: 100%;
  height: 3px;
  background-color: #B3E5FC;
  margin: 0px auto;
}

.Navigation_Indeterminate__zecoV {
  position: relative;
  width: 100%;
  height: 100%;
}

.Navigation_Indeterminate__zecoV:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #03A9F4;
  -webkit-animation: Navigation_indeterminate_first__14abF 1.5s infinite ease-out;
          animation: Navigation_indeterminate_first__14abF 1.5s infinite ease-out;
}

.Navigation_Indeterminate__zecoV:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #4FC3F7;
  -webkit-animation: Navigation_indeterminate_second__JWSF4 1.5s infinite ease-in;
          animation: Navigation_indeterminate_second__JWSF4 1.5s infinite ease-in;
}

.Navigation_CloseParent__YHbez{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 15px;
  align-items: center;
}

@-webkit-keyframes Navigation_indeterminate_first__14abF {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes Navigation_indeterminate_first__14abF {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@-webkit-keyframes Navigation_indeterminate_second__JWSF4 {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes Navigation_indeterminate_second__JWSF4 {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}
.NewsDetails_NewsImage__3GCxR{
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.NewsDetails_NewsHead__3srwO
{
    color: rgb(84,84,84);
    margin-top: 10px;
    line-height: 1.5;
    font-size: 1.4rem !important;
}

.NewsDetails_NewsHeadDark__vItru
{
    color: #d1d1d1;
    margin-top: 10px;
    line-height: 1.5;
    font-size: 1.4rem !important;
}

.NewsDetails_NewsDetailsContent__YnVXe{
    color: rgb(84,84,84);
    text-align: left;
    text-justify: auto;
    font-size: 1rem !important;
    line-height: 1.6 !important;
}

.NewsDetails_NewsDetailsContentDark__1DHns{
    color: #d1d1d1;
    text-align: left;
    text-justify: auto;
    font-size: 1rem !important;
    line-height: 1.6 !important;
}

.NewsDetails_NewsDate__K0gqI{
    color: #9E9E9E;
    margin-top: 5px;
}
.PageNotFound_Parent__25Lkp{
    background: rgba(255,255,255,.7);
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif !important;
    width: 100%;
    padding: 10px;
}

.PageNotFound_ContainerBlock__3ajLx{
    text-align: center;
}

.DateData_DateSelector__DXCg2{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #fff;
}

.DateData_DateContainer__nZdOY{
    border: 2px solid #fff;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 1rem;
}

.DateData_DateContainerDark__1v9JH{
    border: 2px solid #d1d1d1;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 1rem;
}

.DateData_DateValue__19GwE{
    font-size: 1.1rem;
    padding: 5px 15px;
    color: #fafafa;
}

.DateData_DateValueDark__2dM6N{
    font-size: 1.1rem;
    padding: 5px 15px;
    color: #d1d1d1;
}

.DateData_TopSection__14dso{
    background-color: rgb(0,142,223);
    position: fixed;
    width: 100%;
}

.DateData_TopSectionDark__Nlwgo{
    background-color: #06599f;
    position: fixed;
    width: 100%;
}

.DateData_SunUpIcon__1bc8h, .DateData_SunDownIcon__2rL8C{
    height: 40px;
}

.DateData_SunTimeSection__UtYxZ{
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.DateData_WeatherIcon__5fiuU{
    height: 40px;
}

.DateData_TableWeather__2FdxN{
    width: 100%;
    border: none;
}

.DateData_TableWeather__2FdxN thead{
    background-color: rgb(0,142,223);
    color: #fafafa;
    font-weight: 400;
}

.DateData_TableWeather__2FdxN th{
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    color: #fafafa;
}

.DateData_TableWeather__2FdxN td{
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #EEEEEE;
    font-weight: 300;
    color: rgb(0,142,223);
}

.DateData_TableWeather__2FdxN tbody tr td:nth-child(2), .DateData_TableWeather__2FdxN tbody tr td:nth-child(4){
    background-color: transparent;
}

.DateData_TableWeather__2FdxN tbody tr td:nth-child(1), .DateData_TableWeather__2FdxN thead tr td:nth-child(1){
    width: 20%;
}

.DateData_TableWeather__2FdxN tbody tr td:nth-child(2), .DateData_TableWeather__2FdxN thead tr td:nth-child(2){
    width: 15%;
}

.DateData_TableWeather__2FdxN tbody tr td:nth-child(3), .DateData_TableWeather__2FdxN thead tr td:nth-child(3){
    width: 15%;
}

.DateData_TableWeather__2FdxN tbody tr td:nth-child(4), .DateData_TableWeather__2FdxN thead tr td:nth-child(4){
    width: 15%;
}

.DateData_TableWeather__2FdxN tbody tr td:nth-child(5), .DateData_TableWeather__2FdxN thead tr td:nth-child(5){
    width: 20%;
}

.DateData_TableWeatherDark__2WqpF{
    width: 100%;
    border: none;
}

.DateData_TableWeatherDark__2WqpF thead{
    background-color: #06599f;
    color: #d1d1d1;
    font-weight: 400;
}

.DateData_TableWeatherDark__2WqpF th{
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    color: #d1d1d1;
}

.DateData_TableWeatherDark__2WqpF td{
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #424242;
    font-weight: 300;
    color: #d1d1d1;
}

.DateData_TableWeatherDark__2WqpF tbody tr td:nth-child(2), .DateData_TableWeatherDark__2WqpF tbody tr td:nth-child(4){
    background-color: transparent;
}

.DateData_TableWeatherDark__2WqpF tbody tr td:nth-child(1), .DateData_TableWeatherDark__2WqpF thead tr td:nth-child(1){
    width: 20%;
}

.DateData_TableWeatherDark__2WqpF tbody tr td:nth-child(2), .DateData_TableWeatherDark__2WqpF thead tr td:nth-child(2){
    width: 15%;
}

.DateData_TableWeatherDark__2WqpF tbody tr td:nth-child(3), .DateData_TableWeatherDark__2WqpF thead tr td:nth-child(3){
    width: 15%;
}

.DateData_TableWeatherDark__2WqpF tbody tr td:nth-child(4), .DateData_TableWeatherDark__2WqpF thead tr td:nth-child(4){
    width: 15%;
}

.DateData_TableWeatherDark__2WqpF tbody tr td:nth-child(5), .DateData_TableWeatherDark__2WqpF thead tr td:nth-child(5){
    width: 20%;
}

.DateData_TextLight__2JcQ8{
    color: #fafafa;
}

.DateData_TextDark__1juCT{
    color: #d1d1d1;
}
.Layout_NoTokenParent__3DPYU{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #d32f2f;
}

.Layout_ErsteLadung__21Hfq{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
}

.Layout_LocationLoader__RIOmi {
border: 7px solid transparent;
border-radius: 50%;
border-top: 7px solid rgb(0,62,133);
border-bottom: 7px solid rgb(0,62,133);
width: 30px;
height: 30px;
-webkit-animation: Layout_spin__lHecX 1s linear infinite;
animation: Layout_spin__lHecX 1s linear infinite;
z-index:999
}

@-webkit-keyframes Layout_spin__lHecX {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes Layout_spin__lHecX {
0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Layout_LinearActivity__3ILf0 {
  overflow: hidden;
  width: 100%;
  height: 3px;
  background-color: #B3E5FC;
  margin: 0px auto;
}

.Layout_Indeterminate__2C6_S {
  position: relative;
  width: 100%;
  height: 100%;
}

.Layout_Indeterminate__2C6_S:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #03A9F4;
  -webkit-animation: Layout_indeterminate_first__3Hn5P 1.5s infinite ease-out;
          animation: Layout_indeterminate_first__3Hn5P 1.5s infinite ease-out;
}

.Layout_Indeterminate__2C6_S:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #4FC3F7;
  -webkit-animation: Layout_indeterminate_second__2e9RK 1.5s infinite ease-in;
          animation: Layout_indeterminate_second__2e9RK 1.5s infinite ease-in;
}

@-webkit-keyframes Layout_indeterminate_first__3Hn5P {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes Layout_indeterminate_first__3Hn5P {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@-webkit-keyframes Layout_indeterminate_second__2e9RK {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes Layout_indeterminate_second__2e9RK {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

.Layout_LocationLoader__RIOmi {
  border: 10px solid #EEEEEE;
  border-radius: 50%;
  border-top: 10px solid rgb(0,62,133);
  border-bottom: 10px solid rgb(0,62,133);
  width: 75px;
  height: 75px;
  -webkit-animation: Layout_spin__lHecX 2s linear infinite;
  animation: Layout_spin__lHecX 1s linear infinite;
  z-index:999
}

@-webkit-keyframes Layout_spin__lHecX {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes Layout_spin__lHecX {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.SomethingWentWrong_Parent__3SRy5{
    background: rgba(255,255,255,.7);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif !important;
    width: 100%;
}

.SomethingWentWrong_ContainerBlock__2H-q6{
    text-align: center;
}


.Header_Header__3TKM- {
  width: 100%;
}

.Header_HeaderParent__1L-4M {
  position: relative;
}

.Header_LogoContainer__1cOGc {
  z-index: 999;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.Header_LogoParent__3dQ_B {
  padding: 13px 10px 13px 10px;
  background-color: #fff;
  z-index: 999;
}

.Header_Parallelogram__1J3Vg {
  width: 80px;
  height: 100%;
  -webkit-transform: skew(-40deg);
          transform: skew(-40deg);
  background: #fff;
  position: absolute;
  left: 196px;
  box-shadow: 4px -8px 15px -1px rgba(17, 17, 26, 0.3);
  z-index: 990;
}

.Header_Logo__Es3x6 {
  max-height: 55px;
}

.Header_NavParent__3OwaX {
  background-color: transparent;
  flex-grow: 1;
  position: relative;
}

.Header_NavTop__3ytyX {
  width: 100%;
  height: 45px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.Header_NavBottom__Qp3-H {
  height: 50%;
}

.Header_SearchParent__DSAvM {
  min-height: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 35px;
}

.Header_SearchContainer__bIF40 {
  margin-top: 7px;
  background-color: #d6d7d5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  width: 45%;
  height: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.Header_SearchIcon__1nXZi {
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}

.Header_NavigationMenu__aBa3E {
  background-color: rgb(0, 108, 189);
  height: 99%;
  padding-left: 45px;
  color: #fff;
  width: 45px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: stretch;
  transition: width 0.5s ease-out;
  -webkit-transform: skew(-40deg);
          transform: skew(-40deg);
  position: absolute;
  left: 240px;
  height: 39px;
  z-index: 998;
}

.Header_NavigationMenu__aBa3E .Header_MenuIcon__3uwIj {
  flex-grow: 0;
  -webkit-transform: skew(40deg);
          transform: skew(40deg);
  cursor: pointer;
  padding-top: 7px;
}

.Header_MainMenuNavigation__ftiJZ {
  /*height: 0px;
  width: 0%;*/
  width: 310px;
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 39px;
  -webkit-animation-name: Header_menuToHide__pDvQi;
          animation-name: Header_menuToHide__pDvQi;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}

.Header_MainMenuNavigationFirst__1_sjd {
  width: 310px;
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 39px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}

.Header_MainMenuNavigationFirst__1_sjd .Header_MainMenuNavContainer__2I7-E {
  opacity: 0;
  pointer-events: none;
}

.Header_MainMenuNavigation__ftiJZ .Header_MainMenuNavContainer__2I7-E {
  -webkit-animation-name: Header_menuToNavHide__3QaJ1;
          animation-name: Header_menuToNavHide__3QaJ1;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  opacity: 0;
  height: 100%;
  pointer-events: none;
}

.Header_MainMenuNavigationOpen__3llrE {
  background-color: rgb(0, 108, 189);
  -webkit-animation-name: Header_menuToShow__12V1z;
          animation-name: Header_menuToShow__12V1z;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  height: 39px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}

.Header_MainMenuNavigationOpen__3llrE .Header_MainMenuNavContainer__2I7-E {
  -webkit-animation-name: Header_menuToNavShow__tSNGO;
          animation-name: Header_menuToNavShow__tSNGO;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  height: 100%;
  pointer-events: all;
}

@-webkit-keyframes Header_menuToHide__pDvQi {
  0% {
    width: 100%;
  }
  50% {
    width: 310px;
  }
  100% {
    width: 310px;
  }
}

@keyframes Header_menuToHide__pDvQi {
  0% {
    width: 100%;
  }
  50% {
    width: 310px;
  }
  100% {
    width: 310px;
  }
}

@-webkit-keyframes Header_menuToShow__12V1z {
  0% {
    width: 310px;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes Header_menuToShow__12V1z {
  0% {
    width: 310px;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes Header_menuToNavShow__tSNGO {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Header_menuToNavShow__tSNGO {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes Header_menuToNavHide__3QaJ1 {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Header_menuToNavHide__3QaJ1 {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.Header_NavContainer__NS94M {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;
  height: 100%;
}

.Header_NavContainerItem__Rlx1V {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  width: 15%;
  justify-content: center;
  color: #fff;
  transition: 0.5s;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  font-weight: 300 !important;
}

@media (max-width: 1615px){

.Header_NavContainer__NS94M {
  justify-content: flex-end;
}

}

@media (max-width: 1350px){

.Header_NavContainer__NS94M {
  justify-content: flex-end;
}

.Header_NavContainerItem__Rlx1V {
  width: 13%;
}

}

@media (max-width: 1000px){

.Header_NavContainer__NS94M {
  justify-content: flex-end;
}

.Header_NavContainerItem__Rlx1V {
  width: 12%;
}

}

.Header_NavContainerItem__Rlx1V:focus,
.Header_NavContainerItem__Rlx1V:active,
.Header_NavContainerItem__Rlx1V:hover {
  color: rgb(0, 108, 189);
  background-color: #fff;
}

.Header_NavContainerItemIcon__3dHpj {
  padding-top: 7px;
}

/*  MEGA MENU*/

.Header_DropdownContent__3x9Bc {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Header_DropdownContent__3x9Bc .Header_Header__3TKM- {
  background: red;
  padding: 16px;
  color: white;
}

.Header_NavContainerItem__Rlx1V:hover .Header_DropdownContent__3x9Bc {
  display: block;
}

.Header_MegaMenu__ex416 {
  background: transparent;
  width: 100%;
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  z-index: 10;
}

.Header_MegaMenu__ex416 .Header_Hide__O7qL7 {
  display: none;
  opacity: 0;
}

.Header_MegaMenu__ex416 .Header_Visible__3ToaU {
  display: block;
  opacity: 1;
}

/*@keyframes megaMenuHiding {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 0;
    display: none;
  }
}*/

.Header_MenuParent__2ZnvB {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

.Header_MenuSectionLeft__eUGj- {
  background-color: #eeeeee;
  padding: 25px;
  flex-basis: 25%;
  border-bottom-left-radius: 5px;
}

.Header_MenuSectionRight__172-P {
  background-color: #bbdefb;
  padding: 25px;
  flex-basis: 75%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  border-bottom-right-radius: 5px;
}

.Header_MenuImage__kJRVT {
  height: 230px;
}

.Header_MenuSectionRightLinks__1eKbm {
  flex-grow: 1;
}

.Header_MenuSectionRightLinks__1eKbm table {
  min-width: 70%;
}

.Header_MenuSectionRightLinks__1eKbm h6 {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #212121;
  margin-top: 2px;
  margin-bottom: 0px;
}

.Header_MenuSectionRightLinks__1eKbm td {
  font-size: 1rem;
  font-weight: 300;
  color: #212121;
  cursor: pointer;
  transition: 0.5s;
  line-height: 1.4;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.Header_MenuSectionRightLinks__1eKbm td.Header_Divide__2U1MU {
  padding-top: 15px;
}

.Header_MenuSectionRightLinks__1eKbm td:hover {
  color: rgb(0, 108, 189);
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}

.Header_MenuSectionRightImages__3n-_Z {
  flex-grow: 0;
  min-width: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.Header_MenuSectionLeftItem__2ikbd {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 5px 0px;
  color: #212121;
  cursor: pointer;
  transition: 0.5s;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.Header_MenuSectionLeftItem__2ikbd a {
  color: #212121;
}

.Header_MenuSectionLeftItem__2ikbd:hover {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  color: rgb(0, 108, 189);
}

.Header_MenuSectionLeftItem__2ikbd a:hover {
  color: rgb(0, 108, 189);
}

.Header_MenuAktuellNachriten__Mju6- {
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 315px;
  font-size: 1.1rem !important;
}

.Header_MenuAktuellNachriten__Mju6-::first-letter {
  font-weight: 400;
}

.Header_HrLine__3D3Ak {
  width: 75%;
  display: inline-block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(0, 108, 189, 0.2);
  -webkit-margin-before: 2px;
          margin-block-start: 2px;
  -webkit-margin-after: 2px;
          margin-block-end: 2px;
}

.AnziegeKachelComponent_AnzigeParent__16osp{
    width: 100%;
    height: auto;
    /*border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;*/

    
}

.AnziegeKachelComponent_VertilaleWerbung__198qK
{
    margin-bottom: .5rem;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 3px;
    padding: 10rem 1rem;
    color: rgb(84,84,84);
    text-align: center;
    background: rgba(255, 255, 255, .2);
}

.AnziegeKachelComponent_HorizontaleWerbung__1vzEP{
    margin-top: .4rem;
    border: 1px solid rgba(255, 255, 255, .3);
    border-radius: 3px;
    padding: 5rem 1rem;
    color: rgb(84,84,84);
    text-align: center;
    background: rgba(255, 255, 255, .5);
}
.PageNotFound_Parent__WLn1X{
    background: rgba(255,255,255,.7);
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif !important;
    width: 100%;
}

.PageNotFound_ContainerBlock__zCFp7{
    text-align: center;
}


.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp {
  background-color: #fff;
  padding: 10px 20px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
}

.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp .WetterTVWeiterComponent_ComponentLabel__3tbk8 {
  font-family: "Source Sans Pro", sans-serif !important;
  color: #616161;
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 1;
  margin-top: 0.5rem;
}

.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp .WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVItem__hzGZ0 .WetterTVWeiterComponent_WetterImage__2mSp7 {
  height: 225px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp .WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVItem__hzGZ0 .WetterTVWeiterComponent_WetterImage__2mSp7 img {
  width: 50px;
}

.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp .WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVRow__2DRIH {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp .WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVRow__2DRIH .WetterTVWeiterComponent_WetterTVItem__hzGZ0 {
  flex-grow: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-bottom: 10px;
  transition: 0.2s ease-in;
  border-radius: 3px;
}

.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp .WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVRow__2DRIH .WetterTVWeiterComponent_WetterTVItem__hzGZ0:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
}

/*.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:first-of-type{
    margin-right: 5px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:last-of-type{
    margin-left: 5px;
}*/

.WetterTVWeiterComponent_WetterTVKachelParent__3p-pp .WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVItem__hzGZ0 .WetterTVWeiterComponent_WetterData__GB9TK {
  padding: 5px 10px;
}

.WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVItem__hzGZ0 .WetterTVWeiterComponent_WetterDataDate__YIwsl {
  color: #757575;
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  line-height: 0.5;
  margin-top: 7px;
}

.WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVItem__hzGZ0 .WetterTVWeiterComponent_WetterDataTitle__27WVi {
  font-size: 1.1rem;
  font-family: "Source Sans Pro", sans-serif !important;
  color: rgba(0, 62, 133, 0.6);
  line-height: 1;
  margin-top: 1rem;
}

.WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WetterTVItem__hzGZ0 .WetterTVWeiterComponent_WetterDataBody__3VJlP {
  font-size: 0.8rem;
  margin-top: 10px;
  color: rgb(84,84,84);
  font-family: "Source Sans Pro", sans-serif !important;
}

.WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WeiterNewsLink__1-wUG {
  color: rgba(0, 62, 133, 0.6);
  text-decoration: none;
  transition: 0.2s ease-in;
}

.WetterTVWeiterComponent_WetterTVContainer__23rXO .WetterTVWeiterComponent_WeiterNewsLink__1-wUG:hover {
  color: rgba(0, 62, 133, 1);
  text-decoration: none;
}

.WetterTVWeiterComponent_MehrVideos__3TDuH {
  text-align: center;
  margin-top: 2rem;
}

.WetterTVWeiterComponent_BreadcrubsLink__1fS_1 {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
}

.WetterTVWeiterComponent_BreadcrubsLink__1fS_1:hover,
.WetterTVWeiterComponent_BreadcrubsLink__1fS_1:focus,
.WetterTVWeiterComponent_BreadcrubsLink__1fS_1:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.WetterTVWeiterComponent_BreadcrubsNonLink__JAmpi {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.WetterTVWeiterComponent_HeadSection__2mwrL {
  margin-bottom: 1.5rem;
}

.WetterTVWeiterComponent_VideoLink__1KhGN{
    text-decoration: none !important;
}

.WetterTVSingleComponent_WetterTVKachelParent__5U_Ie {
  background-color: #fff;
  padding: 10px 20px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: .5rem;
}

.WetterTVSingleComponent_HeadSection__1fxKC {
  margin-bottom: 1.5rem;
}

.WetterTVSingleComponent_BreadcrubsLink__2pCQZ {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
}

.WetterTVSingleComponent_BreadcrubsLink__2pCQZ:hover,
.WetterTVSingleComponent_BreadcrubsLink__2pCQZ:focus,
.WetterTVSingleComponent_BreadcrubsLink__2pCQZ:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.WetterTVSingleComponent_BreadcrubsNonLink__2JqiG {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.WetterTVSingleComponent_VideoHead__3vD32{
    font-size: 3rem;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 300;
}

.WetterTVSingleComponent_VideoDetails__8pQBz{
    font-size: 1rem;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 500;
}

.WetterTVSingleComponent_VideoLink__2afCN{
    text-decoration: none !important;
}


.WetterTVSingleComponent_WetterTVKachelParent__5U_Ie .WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVItem__1xvP5 .WetterTVSingleComponent_WetterImage__2QyLL {
  height: 225px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.WetterTVSingleComponent_WetterTVKachelParent__5U_Ie .WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVItem__1xvP5 .WetterTVSingleComponent_WetterImage__2QyLL img {
  width: 50px;
}

.WetterTVSingleComponent_WetterTVKachelParent__5U_Ie .WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVRow__xqw7z {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.WetterTVSingleComponent_WetterTVKachelParent__5U_Ie .WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVRow__xqw7z .WetterTVSingleComponent_WetterTVItem__1xvP5 {
  flex-grow: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-bottom: 10px;
  transition: 0.2s ease-in;
  border-radius: 3px;
}

.WetterTVSingleComponent_WetterTVKachelParent__5U_Ie .WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVRow__xqw7z .WetterTVSingleComponent_WetterTVItem__1xvP5:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
}

/*.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:first-of-type{
    margin-right: 5px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:last-of-type{
    margin-left: 5px;
}*/

.WetterTVSingleComponent_WetterTVKachelParent__5U_Ie .WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVItem__1xvP5 .WetterTVSingleComponent_WetterData__257gm {
  padding: 5px 10px;
}

.WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVItem__1xvP5 .WetterTVSingleComponent_WetterDataDate__2LIWI {
  color: #757575;
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  line-height: 0.5;
  margin-top: 7px;
}

.WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVItem__1xvP5 .WetterTVSingleComponent_WetterDataTitle__17Hz_ {
  font-size: 1.1rem;
  font-family: "Source Sans Pro", sans-serif !important;
  color: rgba(0, 62, 133, 0.6);
  line-height: 1;
  margin-top: 1rem;
}

.WetterTVSingleComponent_WetterTVContainer__aArM3 .WetterTVSingleComponent_WetterTVItem__1xvP5 .WetterTVSingleComponent_WetterDataBody__L_YvL {
  font-size: 0.8rem;
  margin-top: 10px;
  color: rgb(84,84,84);
  font-family: "Source Sans Pro", sans-serif !important;
}

.WetterTVSingleComponent_AuchInteressant__Cykj0{

margin-top: 3rem;

}

.LocationSearch_SearchParent__3XY9Q {
  min-height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 35px;
}

.LocationSearch_SearchContainer__1wpc1 {
  margin-top: 7px;
  background-color: #d6d7d5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  height: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.LocationSearch_SearchIcon__2jS40 {
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}
.LocationSearchResult_LocationSearchResultParent__30Smj {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: .5rem;
}

.LocationSearchResult_HeadSection__2WUjo {
    margin-bottom: 1.5rem;
}

.LocationSearchResult_BreadcrubsLink__RKdsq {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
}

.LocationSearchResult_BreadcrubsLink__RKdsq:hover,
.LocationSearchResult_BreadcrubsLink__RKdsq:focus,
.LocationSearchResult_BreadcrubsLink__RKdsq:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
}

.LocationSearchResult_BreadcrubsNonLink__C9Lmv {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
}

.LocationSearchResult_PageHead__1Q-nE{
    font-size: 2.2rem;
    font-weight: 300;
    font-family: "Source Sans Pro", sans-serif !important;
    color: rgba(0, 62, 133, 0.6) ;
}

.LocationSearchResult_PageSubHead__1ccVb{
    font-size: 1.1rem;
    font-weight: 300;
    font-family: "Source Sans Pro", sans-serif !important;
    /*color: rgba(84, 84, 84, 1) ;*/
}

.LocationSearchResult_LocationListItem__1xm0J{
    padding: 10px 0px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 1.2rem;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 300;
    transition: 0.5s;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
}

.LocationSearchResult_LocationListItem__1xm0J:hover {
    color: rgb(0, 108, 189);
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
}

.LocationSearchResult_ListSection__3qtxn{
    margin-top: 1rem;
}
.Wetterlegende_WetterSymbolLegendeParent__28TwE {
  background-color: #fff;
  padding: 10px 20px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: 0.5rem;
}

.Wetterlegende_HeadSection__2AYf6 {
  margin-bottom: 1.5rem;
}

.Wetterlegende_BreadcrubsLink__3aXio {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
  cursor: pointer;
  font-weight: 00;
}

.Wetterlegende_BreadcrubsLink__3aXio:hover,
.Wetterlegende_BreadcrubsLink__3aXio:focus,
.Wetterlegende_BreadcrubsLink__3aXio:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.Wetterlegende_BreadcrubsNonLink__3slOQ {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
  color: rgba(34, 34, 34, .7);
  font-weight: 400;
}

.Wetterlegende_DetailsSection__3DFd3 {
  margin-bottom: 2rem;
}

.Wetterlegende_BtmSection__xvdzS {
  margin-top: 1.5rem;
}
.WetterNetMobil_MobilParent__3wGnD {
  background-color: #fff;
  padding: 10px 20px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: 0.5rem;
}

.WetterNetMobil_HeadSection__e5YY8 {
  margin-bottom: 1.5rem;
}

.WetterNetMobil_BreadcrubsLink__3_0Kt {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
}

.WetterNetMobil_BreadcrubsLink__3_0Kt:hover,
.WetterNetMobil_BreadcrubsLink__3_0Kt:focus,
.WetterNetMobil_BreadcrubsLink__3_0Kt:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.WetterNetMobil_BreadcrubsNonLink__2FgZL {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.WetterNetMobil_DetailsSection__W3GRT {
  margin-bottom: 2rem;
}

.WetterNetMobil_BtmSection__2pjPL {
  margin-top: 1.5rem;
}
.Niederschlagsradar_StromungsfilmParent__s4LOd {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .Niederschlagsradar_HeadSection__3QL69 {
    margin-bottom: 1.5rem;
  }
  
  .Niederschlagsradar_BreadcrubsLink__JMFRe {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .Niederschlagsradar_BreadcrubsLink__JMFRe:hover,
  .Niederschlagsradar_BreadcrubsLink__JMFRe:focus,
  .Niederschlagsradar_BreadcrubsLink__JMFRe:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .Niederschlagsradar_BreadcrubsNonLink__StxWJ {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .Niederschlagsradar_DetailsSection__1vmZx {
    margin-bottom: 2rem;
  }
  
  .Niederschlagsradar_BtmSection__1VZS2 {
    margin-top: 1.5rem;
  }
.Wolkenfilm_StromungsfilmParent__2yDnJ {
    background-color: #fff;
    padding: 10px 20px 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }

  .Wolkenfilm_HeadSection__1DUvC {
    margin-bottom: 1.5rem;
  }

  .Wolkenfilm_BreadcrubsLink__2d0zt {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }

  .Wolkenfilm_BreadcrubsLink__2d0zt:hover,
  .Wolkenfilm_BreadcrubsLink__2d0zt:focus,
  .Wolkenfilm_BreadcrubsLink__2d0zt:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }

  .Wolkenfilm_BreadcrubsNonLink__kVxLR {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }

  .Wolkenfilm_DetailsSection__1d7WK {
    margin-bottom: 2rem;
  }

  .Wolkenfilm_BtmSection__3MF9T {
    margin-top: 1.5rem;
  }
.Stromungsfilm_StromungsfilmParent__297s5 {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .Stromungsfilm_HeadSection__1QXbc {
    margin-bottom: 1.5rem;
  }
  
  .Stromungsfilm_BreadcrubsLink__2HvnT {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .Stromungsfilm_BreadcrubsLink__2HvnT:hover,
  .Stromungsfilm_BreadcrubsLink__2HvnT:focus,
  .Stromungsfilm_BreadcrubsLink__2HvnT:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .Stromungsfilm_BreadcrubsNonLink__1C_fH {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .Stromungsfilm_DetailsSection__2ABt1 {
    margin-bottom: 2rem;
  }
  
  .Stromungsfilm_BtmSection__sEc9_ {
    margin-top: 1.5rem;
  }
.Footer_FooterTParent__1TH9-{
    background-color: #fff;
    padding: 15px 20px 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: .7rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}

.Footer_FooterBParent__10oNo{
    margin-top: .7rem;
    background-color: rgb(0, 108, 189);
    color: #fff;
    padding: 15px 20px 20px;
    font-family: 'Source Sans Pro', sans-serif !important;
    position: relative;
}

@media (min-width: 950px) and (max-width: 1750px){
    .Footer_FooterBParent__10oNo{
        margin-top: 3.3rem;
    }
}

.Grosswetterlagenkarte_GrosswetterlagenkarteParent__1SQTA {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .Grosswetterlagenkarte_HeadSection___ZlR9 {
    margin-bottom: 1.5rem;
  }
  
  .Grosswetterlagenkarte_BreadcrubsLink__1gGeD {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .Grosswetterlagenkarte_BreadcrubsLink__1gGeD:hover,
  .Grosswetterlagenkarte_BreadcrubsLink__1gGeD:focus,
  .Grosswetterlagenkarte_BreadcrubsLink__1gGeD:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .Grosswetterlagenkarte_BreadcrubsNonLink__1Is4D {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .Grosswetterlagenkarte_DetailsSection__1bC5E {
    margin-bottom: 2rem;
  }
  
  .Grosswetterlagenkarte_BtmSection__29yX6 {
    margin-top: 1.5rem;
  }
.Gartenwetter_WetterSymbolLegendeParent__3h9tb {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .Gartenwetter_HeadSection__8EMtA {
    margin-bottom: 1.5rem;
  }
  
  .Gartenwetter_BreadcrubsLink__1apTi {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .Gartenwetter_BreadcrubsLink__1apTi:hover,
  .Gartenwetter_BreadcrubsLink__1apTi:focus,
  .Gartenwetter_BreadcrubsLink__1apTi:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .Gartenwetter_BreadcrubsNonLink__3dmAm {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .Gartenwetter_DetailsSection__1bN4h {
    margin-bottom: 2rem;
  }
  
  .Gartenwetter_BtmSection__1At4J {
    margin-top: 1.5rem;
  }

.WetterLexikon_WetterLexikonDetailsParent__2Q9Va {
    background-color: #fff;
    width: 100%;
    padding: 10px 20px 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }

  .WetterLexikon_HeadSection__15LPA {
    margin-bottom: 1.5rem;
  }

  .WetterLexikon_BreadcrubsLink__39aQs {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }

  .WetterLexikon_BreadcrubsLink__39aQs:hover,
  .WetterLexikon_BreadcrubsLink__39aQs:focus,
  .WetterLexikon_BreadcrubsLink__39aQs:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }

  .WetterLexikon_BreadcrubsNonLink__3JI5W {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }

  .WetterLexikon_DetailsSection__3zrCs {
    margin-bottom: 2rem;
  }

  .WetterLexikon_BtmSection__2I51D {
    margin-top: 1.5rem;
  }
.WetterLexikonDetails_WetterLexikonDetailsParent__Wt7Ch {
    background-color: #fff;
    padding: 10px 20px 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }

  .WetterLexikonDetails_HeadSection__YMhpD {
    margin-bottom: 1.5rem;
  }

  .WetterLexikonDetails_BreadcrubsLink__bTWPX {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }

  .WetterLexikonDetails_BreadcrubsLink__bTWPX:hover,
  .WetterLexikonDetails_BreadcrubsLink__bTWPX:focus,
  .WetterLexikonDetails_BreadcrubsLink__bTWPX:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }

  .WetterLexikonDetails_BreadcrubsNonLink__a7zSR {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }

  .WetterLexikonDetails_DetailsSection__2ByuX {
    margin-bottom: 2rem;
  }

  .WetterLexikonDetails_BtmSection__6H2OT {
    margin-top: 1.5rem;
  }
.PollenKalender_PollenKalenderParent__3n7NC {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .PollenKalender_HeadSection__IsS40 {
    margin-bottom: 1.5rem;
  }
  
  .PollenKalender_BreadcrubsLink__2QJa- {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .PollenKalender_BreadcrubsLink__2QJa-:hover,
  .PollenKalender_BreadcrubsLink__2QJa-:focus,
  .PollenKalender_BreadcrubsLink__2QJa-:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .PollenKalender_BreadcrubsNonLink__2yLhJ {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .PollenKalender_DetailsSection__RJr-S {
    margin-bottom: 2rem;
  }
  
  .PollenKalender_BtmSection__1NPV3 {
    margin-top: 1.5rem;
  }

  .PollenKalender_PollenName__2hXlz{
    min-width: 85px;
}

.PollenKalender_DataSection__3nOam{
  overflow-y: auto;
  border-radius: 5px;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}

.PollenKalender_MainTable__3YCk_{
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  border-radius: 5px;
}

.PollenKalender_MainTable__3YCk_ td{
  padding:0px
}

.PollenKalender_SingleTable__3VW-V, .PollenKalender_SingleTable__3VW-V td {
    padding: 0px 1px !important;
    border-spacing: 0px;
    width: 100%;
}

.PollenKalender_TableHead__2cIk1
{
    text-align: center;
    font-size: .8rem;
    font-weight: 500;
} 

.PollenKalenderDetails_PollenName__1U-Wz{
    min-width: 100px;
    padding: 5px 10px !important;
    color: rgba(34, 34, 34, 1);
    font-size: 1.1rem;
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: 300;
}

.PollenKalenderDetails_SingleTable__rjyXo{
    border-spacing: 0px;
    border-collapse: collapse;
    width: 100%;
}

.PollenKalenderDetails_SingleTable__rjyXo, .PollenKalenderDetails_SingleTable__rjyXo .PollenKalenderDetails_Item__1TxRG {
    padding: 8px 1px !important;
    border-spacing: 0px;
}

.PollenKalenderDetails_SingleTable__rjyXo thead tr td:nth-child(odd){
  background-color: #F5F5F5;
}

.PollenKalenderDetails_SingleTable__rjyXo tr{
    border-bottom: 1px solid #E0E0E0;
}

.PollenKalenderDetails_SingleTable__rjyXo .PollenKalenderDetails_ItemOdd__1Hg4J{
  background-color: #F5F5F5;
}

.PollenKalenderDetails_SingleTable__rjyXo .PollenKalenderDetails_ItemBlue__11qCC{
  background-color: rgba(252, 239, 196, .6);
}

.PollenKalenderDetails_TableHead__3W03r
{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding:15px 5px !important;
    color: rgba(34, 34, 34, 1);
    font-size: 1.1rem;
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: 300;
} 

.PollenKalenderDetails_TableHeadBlue__2ux39
{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding:15px 5px !important;
    background-color: rgba(252, 239, 196, .6) !important;
    border-top: 2px solid rgba(252, 239, 196, 1);
    color: rgba(34, 34, 34, 1);
    font-size: 1.1rem;
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: 300;
}
.BiowetterDetailsPage_BiowetterItems__347Md{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.BiowetterDetailsPage_BiowetterItems__347Md .BiowetterDetailsPage_BiowetterItemsRow__CF7ir{
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px;
    width: 25%;
}

.BiowetterDetailsPage_BiowetterItems__347Md .BiowetterDetailsPage_BiowetterItemsRow__CF7ir:first-of-type{
    border-left: 1px solid transparent;
    padding-left: 0px;
}

.BiowetterDetailsPage_BiowetterItemsColumn__PJyIl{
    display:  flex;
    flex-direction: row;
    margin-top: 5px;
}

.BiowetterDetailsPage_BiowetterItemsColumn__PJyIl .BiowetterDetailsPage_BiowetterValues__muDzo{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.BiowetterDetailsPage_BiowetterItems__347Md .BiowetterDetailsPage_BiowetterIcon__1oVDE{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.BiowetterDetailsPage_BiowetterItems__347Md .BiowetterDetailsPage_BiowetterIcon__1oVDE div{
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.BiowetterDetailsPage_BiowetterItemsColumn__PJyIl .BiowetterDetailsPage_BiowetterValues__muDzo{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.BiowetterDetailsPage_BiowetterName__1WB4R{
    margin-left: 8px;
    font-size: 1rem;
    color: #757575;
    font-weight: 400;
    line-height: .9;
}

.BiowetterDetailsPage_BiowetterStrength__yHqT_{
    margin-left: 8px;
    color: rgb(0,62,133);
    font-size: .9rem;
    font-weight: 400 !important;
    line-height: 1.4;
}
.BiowetterInnerPage_WetterSymbolLegendeParent__2Uw78 {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .BiowetterInnerPage_HeadSection__LA_uB {
    margin-bottom: 1.5rem;
  }
  
  .BiowetterInnerPage_BreadcrubsLink__2cNuC {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .BiowetterInnerPage_BreadcrubsLink__2cNuC:hover,
  .BiowetterInnerPage_BreadcrubsLink__2cNuC:focus,
  .BiowetterInnerPage_BreadcrubsLink__2cNuC:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .BiowetterInnerPage_BreadcrubsNonLink__1liQt {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .BiowetterInnerPage_DetailsSection__25YfD {
    margin-bottom: 2rem;
  }
  
  .BiowetterInnerPage_BtmSection__1p2VO {
    margin-top: 1.5rem;
  }

  /*.BiowetterKachelParent{
    background-color: #fff;
    padding: 15px 20px 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: .5rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}*/

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterItems__28W2e{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterItems__28W2e .BiowetterInnerPage_BiowetterItemsRow__2cJVY{
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px;
    width: 25%;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterItems__28W2e .BiowetterInnerPage_BiowetterItemsRow__2cJVY:first-of-type{
    border-left: 1px solid transparent;
    padding-left: 0px;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_ContainerHeading__WmMon{
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #616161;
    font-weight: 300;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterItemsColumn__3TZRS{
    display:  flex;
    flex-direction: row;
    margin-top: 5px;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterItemsColumn__3TZRS .BiowetterInnerPage_BiowetterValues__Xtd_k{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterName__SZhRj{
    margin-left: 8px;
    font-size: 1rem;
    color: #757575;
    font-weight: 400;
    line-height: .9;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterStrength__3A5h2{
    margin-left: 8px;
    color: rgb(0,62,133);
    font-size: .9rem;
    font-weight: 400 !important;
    line-height: 1.4;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterItems__28W2e .BiowetterInnerPage_BiowetterIcon__29enD{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_BiowetterItems__28W2e .BiowetterInnerPage_BiowetterIcon__29enD div{
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_ButtonTags__28kC2{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_ButtonTags__28kC2 div {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 300;
    color: rgba(0,62,133,.6);
    cursor: pointer;
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_ButtonTags__28kC2 div.BiowetterInnerPage_ActiveTab__1Laj3{
    color: rgba(0, 0, 0, 0.6);
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_ButtonTags__28kC2 div:nth-child(even) {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
}

.BiowetterInnerPage_BiowetterItemsContainer__27ZsZ .BiowetterInnerPage_ButtonTags__28kC2 div:first-of-type{
    padding-left: 0px;
}

.BiowetterInnerPage_Loader__1pLBG {
  border: 2px solid #ebebeb;
  border-radius: 50%;
  border-top: 2px solid rgb(0,62,133);
  border-bottom: 2px solid rgb(0,62,133);
  width: 20px;
  height: 20px;
  -webkit-animation: BiowetterInnerPage_spin__2OGaR 1s linear infinite;
  animation: BiowetterInnerPage_spin__2OGaR 1s linear infinite;
  margin-left: 10px;
}

@-webkit-keyframes BiowetterInnerPage_spin__2OGaR {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes BiowetterInnerPage_spin__2OGaR {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.PollenflugInnerPage_WetterSymbolLegendeParent__3xrSf {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .PollenflugInnerPage_HeadSection__kTA03 {
    margin-bottom: 1.5rem;
  }
  
  .PollenflugInnerPage_BreadcrubsLink__1Fd8Y {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .PollenflugInnerPage_BreadcrubsLink__1Fd8Y:hover,
  .PollenflugInnerPage_BreadcrubsLink__1Fd8Y:focus,
  .PollenflugInnerPage_BreadcrubsLink__1Fd8Y:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .PollenflugInnerPage_BreadcrubsNonLink__2AAUl {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .PollenflugInnerPage_DetailsSection__15mfs {
    margin-bottom: 2rem;
  }
  
  .PollenflugInnerPage_BtmSection__VGb-Z {
    margin-top: 1.5rem;
  }

  /*.PollenflugKachelParent{
    background-color: #fff;
    padding: 15px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: .5rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}*/

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugItems__tOF58{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugItems__tOF58 .PollenflugInnerPage_PollenflugItemsRow__OHfyx{
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px;
    width: 25%;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugItems__tOF58 .PollenflugInnerPage_PollenflugItemsRow__OHfyx:first-of-type{
    border-left: 1px solid transparent;
    padding-left: 0px;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ContainerHeading__1ex5g{
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #616161;
    font-weight: 300;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugItemsColumn__vZxNE{
    display:  flex;
    flex-direction: row;
    margin-top: 5px;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugItemsColumn__vZxNE .PollenflugInnerPage_PollenflugValues__3yyqJ{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugName__2NF4m{
    margin-left: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: .9;
    color: #757575;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugStrength__3nFWF{
    margin-left: 8px;
    color: rgb(0,62,133);
    font-size: .9rem;
    font-weight: 400 !important;
    line-height: 1.4;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugItems__tOF58 .PollenflugInnerPage_PollenflugIcon__2qC48{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_PollenflugItems__tOF58 .PollenflugInnerPage_PollenflugIcon__2qC48 div{
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2 div {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 300;
    color: rgba(0,62,133,.6);
    cursor: pointer;
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2 div:hover,
.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2 div:active,
.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2 div:focus {
    color: rgba(0, 0, 0, 0.6);
}


.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2 div.PollenflugInnerPage_ActiveTab__2SDIl{
    color: rgba(0, 0, 0, 0.6);
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2 div:nth-child(even) {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
}

.PollenflugInnerPage_PollenflugItemsContainer__2yE0a .PollenflugInnerPage_ButtonTags__11MV2 div:first-of-type{
    padding-left: 0px;
}

.PollenflugInnerPage_Loader__12JIZ {
  border: 2px solid #ebebeb;
  border-radius: 50%;
  border-top: 2px solid rgb(0,62,133);
  border-bottom: 2px solid rgb(0,62,133);
  width: 20px;
  height: 20px;
  -webkit-animation: PollenflugInnerPage_spin__3zb5f 1s linear infinite;
  animation: PollenflugInnerPage_spin__3zb5f 1s linear infinite;
  margin-left: 10px;
  display: inline-block;
}

@-webkit-keyframes PollenflugInnerPage_spin__3zb5f {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes PollenflugInnerPage_spin__3zb5f {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.PollenflugDetailsPage_PollenflugItems__3yrU4{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PollenflugDetailsPage_PollenflugItems__3yrU4 .PollenflugDetailsPage_PollenflugItemsRow__135Gj{
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px;
    width: 25%;
}

.PollenflugDetailsPage_PollenflugItems__3yrU4 .PollenflugDetailsPage_PollenflugItemsRow__135Gj:first-of-type{
    border-left: 1px solid transparent;
    padding-left: 0px;
}

.PollenflugDetailsPage_PollenflugItemsColumn__2oXqU{
    display:  flex;
    flex-direction: row;
    margin-top: 5px;
}

.PollenflugDetailsPage_PollenflugItemsColumn__2oXqU .PollenflugDetailsPage_PollenflugValues__3L6qK{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.PollenflugDetailsPage_PollenflugItems__3yrU4 .PollenflugDetailsPage_PollenflugIcon__OcXYn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.PollenflugDetailsPage_PollenflugItems__3yrU4 .PollenflugDetailsPage_PollenflugIcon__OcXYn div{
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PollenflugDetailsPage_PollenflugValues__3L6qK{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.PollenflugDetailsPage_PollenflugName__2I_dj{
    margin-left: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: .9;
    color: #757575;
}

.PollenflugDetailsPage_PollenflugStrength__1vRHB{
    margin-left: 8px;
    color: rgb(0,62,133);
    font-size: .9rem;
    font-weight: 400 !important;
    line-height: 1.4;
}
.NewsInnerPage_NewsInnerPageParent__2mPCW {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .NewsInnerPage_HeadSection__hfDpm {
    margin-bottom: 1.5rem;
  }
  
  .NewsInnerPage_BreadcrubsLink__3SPxg {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .NewsInnerPage_BreadcrubsLink__3SPxg:hover,
  .NewsInnerPage_BreadcrubsLink__3SPxg:focus,
  .NewsInnerPage_BreadcrubsLink__3SPxg:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .NewsInnerPage_BreadcrubsNonLink__V2Wd6 {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .NewsInnerPage_DetailsSection__36Dsl {
    margin-bottom: 2rem;
  }
  
  .NewsInnerPage_BtmSection__1uBIu {
    margin-top: 1.5rem;
  }

  /*.WetterNewsKachelParent{
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: .5rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}*/

.NewsInnerPage_ComponentLabel__YA6d3{
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #616161;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 7px;
}

.NewsInnerPage_WetterNewsContainer__38GUT .NewsInnerPage_WetterImage__2hmjX{
    height: 225px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

/*.WetterNewsKachelParent .WetterNewsContainer .WetterNewsRow{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}*/

.NewsInnerPage_WetterNewsContainer__38GUT  .NewsInnerPage_WetterNewsItem__1HN42{
    flex-grow: 1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-bottom: 10px;
    transition: .2s ease-in;
    border-radius: 3px;
    margin-bottom: .1rem;
}

.NewsInnerPage_WetterNewsContainer__38GUT .NewsInnerPage_WetterNewsItem__1HN42:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
}

/*.WetterNewsKachelParent .WetterNewsContainer .WetterNewsItem:first-of-type{
    margin-right: 5px;
}*/

/*.WetterNewsKachelParent .WetterNewsContainer .WetterNewsItem:last-of-type{
    margin-left: 5px;
}*/

.NewsInnerPage_WetterNewsContainer__38GUT .NewsInnerPage_WetterNewsItem__1HN42 .NewsInnerPage_WetterData__ZXAJN{
    padding: 5px 10px;
}

.NewsInnerPage_WetterNewsItem__1HN42 .NewsInnerPage_WetterDataDate__2-2_P{
    color: #757575;
    font-size: .8rem;
    font-family: 'Source Sans Pro', sans-serif !important;
    line-height: .5;
    margin-top: 7px;
}

.NewsInnerPage_WetterNewsItem__1HN42  .NewsInnerPage_WetterDataTitle__3tXUM{
    font-size: 1.1rem;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: rgba(0,62,133, .6);
    line-height: 1;
    margin-top: 15px;
}

.NewsInnerPage_WetterNewsItem__1HN42 .NewsInnerPage_WetterDataBody__3cmYT{
    font-size: .8rem;
    margin-top: 10px;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: rgb(84,84,84);
}

.NewsInnerPage_WeiterNewsLink__ZWFLl{
    color: rgba(0,62,133, .6);
    text-decoration: none;
    transition: .2s ease-in;
    margin-top: 1rem;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 1.1rem;
    font-weight: 300;
}

.NewsInnerPage_WeiterNewsLink__ZWFLl:hover{
    color: rgba(0,62,133, 1);
    text-decoration: none;
}

.NewsInnerPage_VideoLink__2MfNU{
    text-decoration: none !important;
}

.NewsInnerPage_Loader__29_3o {
  border: 2px solid #ebebeb;
  border-radius: 50%;
  border-top: 2px solid rgb(0,62,133);
  border-bottom: 2px solid rgb(0,62,133);
  width: 20px;
  height: 20px;
  -webkit-animation: NewsInnerPage_spin__Vap8Y 1s linear infinite;
  animation: NewsInnerPage_spin__Vap8Y 1s linear infinite;
  margin-left: 10px;
  display: inline-block;
}

.NewsInnerPage_MehrNews___PBWb {
    text-align: center;
    margin-top: 2rem;
  }
  

@-webkit-keyframes NewsInnerPage_spin__Vap8Y {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes NewsInnerPage_spin__Vap8Y {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.NewsSinglePage_WetterTVKachelParent__dteG8 {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: .5rem;
  }
  
  .NewsSinglePage_HeadSection__3MNZM {
    margin-bottom: 1.5rem;
  }
  
  .NewsSinglePage_BreadcrubsLink__B5mpC {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
  }
  
  .NewsSinglePage_BreadcrubsLink__B5mpC:hover,
  .NewsSinglePage_BreadcrubsLink__B5mpC:focus,
  .NewsSinglePage_BreadcrubsLink__B5mpC:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .NewsSinglePage_BreadcrubsNonLink__jAa8W {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
  }
  
  .NewsSinglePage_VideoHead__3iM5D{
      font-size: 3rem;
      font-family: "Source Sans Pro", sans-serif !important;
      font-weight: 300;
  }
  
  .NewsSinglePage_VideoDetails__1rVpo{
      font-size: 1rem;
      font-family: "Source Sans Pro", sans-serif !important;
      font-weight: 500;
  }
  
  .NewsSinglePage_VideoLink__2fG79{
      text-decoration: none !important;
  }
  
  
  .NewsSinglePage_WetterTVKachelParent__dteG8 .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVItem__vzzkR .NewsSinglePage_WetterImage__3P9vx {
    height: 225px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  
  .NewsSinglePage_WetterTVKachelParent__dteG8 .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVItem__vzzkR .NewsSinglePage_WetterImage__3P9vx img {
    width: 50px;
  }
  
  .NewsSinglePage_WetterTVKachelParent__dteG8 .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVRow__2WjNP {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
  }
  
  .NewsSinglePage_WetterTVKachelParent__dteG8 .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVRow__2WjNP .NewsSinglePage_WetterTVItem__vzzkR {
    flex-grow: 1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-bottom: 10px;
    transition: 0.2s ease-in;
    border-radius: 3px;
  }
  
  .NewsSinglePage_WetterTVKachelParent__dteG8 .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVRow__2WjNP .NewsSinglePage_WetterTVItem__vzzkR:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
  }
  
  /*.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:first-of-type{
      margin-right: 5px;
  }
  
  .WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:last-of-type{
      margin-left: 5px;
  }*/
  
  .NewsSinglePage_WetterTVKachelParent__dteG8 .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVItem__vzzkR .NewsSinglePage_WetterData__2ft0R {
    padding: 5px 10px;
  }
  
  .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVItem__vzzkR .NewsSinglePage_WetterDataDate__pORgR {
    color: #757575;
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    line-height: 0.5;
    margin-top: 7px;
  }
  
  .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVItem__vzzkR .NewsSinglePage_WetterDataTitle__3_W4z {
    font-size: 1.1rem;
    font-family: "Source Sans Pro", sans-serif !important;
    color: rgba(0, 62, 133, 0.6);
    line-height: 1;
    margin-top: 1rem;
  }
  
  .NewsSinglePage_WetterTVContainer__3EA-Z .NewsSinglePage_WetterTVItem__vzzkR .NewsSinglePage_WetterDataBody__1pcKM {
    font-size: 0.8rem;
    margin-top: 10px;
    color: rgb(84,84,84);
    font-family: "Source Sans Pro", sans-serif !important;
  }
  
  .NewsSinglePage_AuchInteressant__2NBnr{
  
  margin-top: 3rem;
  
  }
.VideoInnerPage_WetterTVKachelParent__Jqqya {
  background-color: #fff;
  padding: 10px 20px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
}

.VideoInnerPage_WetterTVKachelParent__Jqqya .VideoInnerPage_ComponentLabel__ikYMu {
  font-family: "Source Sans Pro", sans-serif !important;
  color: #616161;
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 1;
  margin-top: 0.5rem;
}

.VideoInnerPage_WetterTVKachelParent__Jqqya .VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVItem__2Dsg8 .VideoInnerPage_WetterImage__JBPTo {
  height: 225px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.VideoInnerPage_WetterTVKachelParent__Jqqya .VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVItem__2Dsg8 .VideoInnerPage_WetterImage__JBPTo img {
  width: 50px;
}

.VideoInnerPage_WetterTVKachelParent__Jqqya .VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVRow__3Y9FQ {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.VideoInnerPage_WetterTVKachelParent__Jqqya .VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVRow__3Y9FQ .VideoInnerPage_WetterTVItem__2Dsg8 {
  flex-grow: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-bottom: 10px;
  transition: 0.2s ease-in;
  border-radius: 3px;
}

.VideoInnerPage_WetterTVKachelParent__Jqqya .VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVRow__3Y9FQ .VideoInnerPage_WetterTVItem__2Dsg8:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
}

/*.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:first-of-type{
    margin-right: 5px;
}

.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:last-of-type{
    margin-left: 5px;
}*/

.VideoInnerPage_WetterTVKachelParent__Jqqya .VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVItem__2Dsg8 .VideoInnerPage_WetterData__k3MNy {
  padding: 5px 10px;
}

.VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVItem__2Dsg8 .VideoInnerPage_WetterDataDate__iJwGJ {
  color: #757575;
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  line-height: 0.5;
  margin-top: 7px;
}

.VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVItem__2Dsg8 .VideoInnerPage_WetterDataTitle__27bia {
  font-size: 1.1rem;
  font-family: "Source Sans Pro", sans-serif !important;
  color: rgba(0, 62, 133, 0.6);
  line-height: 1;
  margin-top: 1rem;
}

.VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WetterTVItem__2Dsg8 .VideoInnerPage_WetterDataBody__w5Sqd {
  font-size: 0.8rem;
  margin-top: 10px;
  color: rgb(84,84,84);
  font-family: "Source Sans Pro", sans-serif !important;
}

.VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WeiterNewsLink__14k4r {
  color: rgba(0, 62, 133, 0.6);
  text-decoration: none;
  transition: 0.2s ease-in;
}

.VideoInnerPage_WetterTVContainer__3-Fop .VideoInnerPage_WeiterNewsLink__14k4r:hover {
  color: rgba(0, 62, 133, 1);
  text-decoration: none;
}

.VideoInnerPage_MehrVideos__2dyK2 {
  text-align: center;
  margin-top: 2rem;
}

.VideoInnerPage_BreadcrubsLink__1zQXm {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
}

.VideoInnerPage_BreadcrubsLink__1zQXm:hover,
.VideoInnerPage_BreadcrubsLink__1zQXm:focus,
.VideoInnerPage_BreadcrubsLink__1zQXm:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.VideoInnerPage_BreadcrubsNonLink__33h75 {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.VideoInnerPage_HeadSection__2aOD4 {
  margin-bottom: 1.5rem;
}

.VideoInnerPage_VideoLink__1hBQg{
    text-decoration: none !important;
}

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ {
  background-color: #fff;
  padding: 10px 20px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: .5rem;
}

.VideoSingleInnerPage_HeadSection__2_DDs {
  margin-bottom: 1.5rem;
}

.VideoSingleInnerPage_BreadcrubsLink__1lDd1 {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
}

.VideoSingleInnerPage_BreadcrubsLink__1lDd1:hover,
.VideoSingleInnerPage_BreadcrubsLink__1lDd1:focus,
.VideoSingleInnerPage_BreadcrubsLink__1lDd1:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.VideoSingleInnerPage_BreadcrubsNonLink__36Dt6 {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.VideoSingleInnerPage_VideoHead__6rRu0{
    font-size: 3rem;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 300;
}

.VideoSingleInnerPage_VideoDetails__2SkJw{
    font-size: 1rem;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 500;
}

.VideoSingleInnerPage_VideoLink__y3o6B{
    text-decoration: none !important;
}

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ .VideoSingleInnerPage_WetterTVContainer__2Nmow{
  margin-bottom: 1rem;
}

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ .VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVItem__3HTC2 .VideoSingleInnerPage_WetterImage__TY3HF {
  height: 225px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ .VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVItem__3HTC2 .VideoSingleInnerPage_WetterImage__TY3HF img {
  width: 50px;
}

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ .VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVRow__pafn3 {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ .VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVRow__pafn3 .VideoSingleInnerPage_WetterTVItem__3HTC2 {
  flex-grow: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-bottom: 10px;
  transition: 0.2s ease-in;
  border-radius: 3px;
}

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ .VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVRow__pafn3 .VideoSingleInnerPage_WetterTVItem__3HTC2:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
}

/*.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:first-of-type{
    margin-right: 5px;
}
.WetterTVKachelParent .WetterTVContainer .WetterTVRow .WetterTVItem:last-of-type{
    margin-left: 5px;
}*/

.VideoSingleInnerPage_WetterTVKachelParent__3dFbQ .VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVItem__3HTC2 .VideoSingleInnerPage_WetterData__3I5VI {
  padding: 5px 10px;
}

.VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVItem__3HTC2 .VideoSingleInnerPage_WetterDataDate__3kTQq {
  color: #757575;
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  line-height: 0.5;
  margin-top: 7px;
}

.VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVItem__3HTC2 .VideoSingleInnerPage_WetterDataTitle__fm1w1 {
  font-size: 1.3rem;
  font-family: "Source Sans Pro", sans-serif !important;
  color: rgba(0, 62, 133, 0.6);
  line-height: 1;
  margin-top: 1rem;
}

.VideoSingleInnerPage_WetterTVContainer__2Nmow .VideoSingleInnerPage_WetterTVItem__3HTC2 .VideoSingleInnerPage_WetterDataBody__2Buex {
  font-size: 0.9rem;
  margin-top: 10px;
  color: rgb(84,84,84);
  font-family: "Source Sans Pro", sans-serif !important;
}

.VideoSingleInnerPage_AuchInteressant__1encV{

margin-top: 3rem;

}


.Navigation_Navigation__2LHJ_{
    margin-top: 1rem;
    min-height: 50vh;
}

.Navigation_Aside__2nLAY{
    width: 100%;
}


