.PollenName{
    min-width: 100px;
    padding: 5px 10px !important;
    color: rgba(34, 34, 34, 1);
    font-size: 1.1rem;
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: 300;
}

.SingleTable{
    border-spacing: 0px;
    border-collapse: collapse;
    width: 100%;
}

.SingleTable, .SingleTable .Item {
    padding: 8px 1px !important;
    border-spacing: 0px;
}

.SingleTable thead tr td:nth-child(odd){
  background-color: #F5F5F5;
}

.SingleTable tr{
    border-bottom: 1px solid #E0E0E0;
}

.SingleTable .ItemOdd{
  background-color: #F5F5F5;
}

.SingleTable .ItemBlue{
  background-color: rgba(252, 239, 196, .6);
}

.TableHead
{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding:15px 5px !important;
    color: rgba(34, 34, 34, 1);
    font-size: 1.1rem;
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: 300;
} 

.TableHeadBlue
{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding:15px 5px !important;
    background-color: rgba(252, 239, 196, .6) !important;
    border-top: 2px solid rgba(252, 239, 196, 1);
    color: rgba(34, 34, 34, 1);
    font-size: 1.1rem;
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: 300;
}