.NewsImage{
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.NewsHead
{
    color: rgb(84,84,84);
    margin-top: 10px;
    line-height: 1.5;
    font-size: 1.4rem !important;
}

.NewsHeadDark
{
    color: #d1d1d1;
    margin-top: 10px;
    line-height: 1.5;
    font-size: 1.4rem !important;
}

.NewsDetailsContent{
    color: rgb(84,84,84);
    text-align: left;
    text-justify: auto;
    font-size: 1rem !important;
    line-height: 1.6 !important;
}

.NewsDetailsContentDark{
    color: #d1d1d1;
    text-align: left;
    text-justify: auto;
    font-size: 1rem !important;
    line-height: 1.6 !important;
}

.NewsDate{
    color: #9E9E9E;
    margin-top: 5px;
}