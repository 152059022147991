.NewsInnerPageParent {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .HeadSection {
    margin-bottom: 1.5rem;
  }
  
  .BreadcrubsLink {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .BreadcrubsLink:hover,
  .BreadcrubsLink:focus,
  .BreadcrubsLink:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .BreadcrubsNonLink {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .DetailsSection {
    margin-bottom: 2rem;
  }
  
  .BtmSection {
    margin-top: 1.5rem;
  }

  /*.WetterNewsKachelParent{
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: .5rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}*/

.ComponentLabel{
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #616161;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 7px;
}

.WetterNewsContainer .WetterImage{
    height: 225px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

/*.WetterNewsKachelParent .WetterNewsContainer .WetterNewsRow{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}*/

.WetterNewsContainer  .WetterNewsItem{
    flex-grow: 1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 20px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-bottom: 10px;
    transition: .2s ease-in;
    border-radius: 3px;
    margin-bottom: .1rem;
}

.WetterNewsContainer .WetterNewsItem:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
}

/*.WetterNewsKachelParent .WetterNewsContainer .WetterNewsItem:first-of-type{
    margin-right: 5px;
}*/

/*.WetterNewsKachelParent .WetterNewsContainer .WetterNewsItem:last-of-type{
    margin-left: 5px;
}*/

.WetterNewsContainer .WetterNewsItem .WetterData{
    padding: 5px 10px;
}

.WetterNewsItem .WetterDataDate{
    color: #757575;
    font-size: .8rem;
    font-family: 'Source Sans Pro', sans-serif !important;
    line-height: .5;
    margin-top: 7px;
}

.WetterNewsItem  .WetterDataTitle{
    font-size: 1.1rem;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: rgba(0,62,133, .6);
    line-height: 1;
    margin-top: 15px;
}

.WetterNewsItem .WetterDataBody{
    font-size: .8rem;
    margin-top: 10px;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: rgb(84,84,84);
}

.WeiterNewsLink{
    color: rgba(0,62,133, .6);
    text-decoration: none;
    transition: .2s ease-in;
    margin-top: 1rem;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 1.1rem;
    font-weight: 300;
}

.WeiterNewsLink:hover{
    color: rgba(0,62,133, 1);
    text-decoration: none;
}

.VideoLink{
    text-decoration: none !important;
}

.Loader {
  border: 2px solid #ebebeb;
  border-radius: 50%;
  border-top: 2px solid rgb(0,62,133);
  border-bottom: 2px solid rgb(0,62,133);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: inline-block;
}

.MehrNews {
    text-align: center;
    margin-top: 2rem;
  }
  

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}