.MobilParent {
  background-color: #fff;
  padding: 10px 20px 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: 0.5rem;
}

.HeadSection {
  margin-bottom: 1.5rem;
}

.BreadcrubsLink {
  color: rgba(0, 62, 133, 0.6) !important;
  text-decoration: none !important;
  transition: 0.2s ease-in;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif !important;
}

.BreadcrubsLink:hover,
.BreadcrubsLink:focus,
.BreadcrubsLink:active {
  color: rgba(0, 62, 133, 1) !important;
  text-decoration: none !important;
}

.BreadcrubsNonLink {
  font-size: 0.8rem;
  font-family: "Source Sans Pro", sans-serif !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.DetailsSection {
  margin-bottom: 2rem;
}

.BtmSection {
  margin-top: 1.5rem;
}