.Parent{
    background: rgba(255,255,255,.7);
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif !important;
    width: 100%;
}

.ContainerBlock{
    text-align: center;
}

