.DrawerContainer {
  position: relative;
}

.MenuHeaderParent,
.MenuParent {
  min-height: 25vh;
  min-width: 75vw;
  padding: 10px;
}

.MenuActive{
    background-color: rgb(0, 142, 223);
}

.MenuHeader {
  background: rgb(0, 142, 223);
  background: linear-gradient(
    170deg,
    rgba(0, 142, 223, 1) 0%,
    rgba(0, 173, 223, 1) 50%,
    rgba(0, 142, 223, 1) 100%
  );
  min-height: 100%;
  border-radius: 10px;
  color: #fff;
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin: 0px 10px;
}

.MenuHeaderDark {
  background: #252525;
  min-height: 100%;
  border-radius: 10px;
  color: #d1d1d1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin: 0px 10px;
}

.MenuFooter {
  color: #fff;
  background-color: rgb(84, 84, 84);
  /*position: absolute;*/
  min-width: 75vw;
  width: auto;
  /*bottom: 0;*/
  text-align: center;
  padding: 8px 10px 10px 10px;
  font-size: 0.9rem;
}

.CustomTabControl {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  background-color: #FFFFFF;
}

.CustomTabControl .CustomTabItem {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px 0px;
  color: rgb(84, 84, 84);
  text-decoration: none;
  text-align: center;
}

.CustomTabControl .CustomTabItemActive {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgb(0, 62, 133);
  color: #fff;
  padding: 5px 0px;
}

.CustomTabControlDark {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  background-color: #252525;
}

.CustomTabControlDark .CustomTabItem {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px 0px;
  color: #d1d1d1;
  text-decoration: none;
  text-align: center;
}

.CustomTabControlDark .CustomTabItemActive {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #0c2140;
  color: #fff;
  padding: 5px 0px;
}

.CustomTabItemCenter {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: rgb(0, 62, 133);
  position: relative;
}

.CustomTabItemCenter .MainCircle{
  position: absolute;
  bottom: 10px;
}

.NavLink{
    text-decoration: none;
    color: inherit;
}

.TabImage{
  height: 20px;
  margin-top: 5px;
}

.TabImageHome{
  height:60px;
}

.LoadingParent{
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
  align-content: center;
  height: 100%;
}

.Loader {
  border: 10px solid #EEEEEE;
  border-radius: 50%;
  border-top: 10px solid rgb(0,62,133);
  border-bottom: 10px solid rgb(0,62,133);
  width: 75px;
  height: 75px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
  z-index:999
}

.MenuIcons{
  height: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ErsteLadung{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
}

.LinearActivity {
  overflow: hidden;
  width: 100%;
  height: 3px;
  background-color: #B3E5FC;
  margin: 0px auto;
}

.Indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.Indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #03A9F4;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.Indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #4FC3F7;
  animation: indeterminate_second 1.5s infinite ease-in;
}

.CloseParent{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 15px;
  align-items: center;
}

@keyframes indeterminate_first {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}