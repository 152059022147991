.Header {
  width: 100%;
}

.HeaderParent {
  position: relative;
}

.LogoContainer {
  z-index: 999;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.LogoParent {
  padding: 13px 10px 13px 10px;
  background-color: #fff;
  z-index: 999;
}

.Parallelogram {
  width: 80px;
  height: 100%;
  transform: skew(-40deg);
  background: #fff;
  position: absolute;
  left: 196px;
  box-shadow: 4px -8px 15px -1px rgba(17, 17, 26, 0.3);
  z-index: 990;
}

.Logo {
  max-height: 55px;
}

.NavParent {
  background-color: transparent;
  flex-grow: 1;
  position: relative;
}

.NavTop {
  width: 100%;
  height: 45px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.NavBottom {
  height: 50%;
}

.SearchParent {
  min-height: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 35px;
}

.SearchContainer {
  margin-top: 7px;
  background-color: #d6d7d5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  width: 45%;
  height: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.SearchIcon {
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}

.NavigationMenu {
  background-color: rgb(0, 108, 189);
  height: 99%;
  padding-left: 45px;
  color: #fff;
  width: 45px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: stretch;
  transition: width 0.5s ease-out;
  transform: skew(-40deg);
  position: absolute;
  left: 240px;
  height: 39px;
  z-index: 998;
}

.NavigationMenu .MenuIcon {
  flex-grow: 0;
  transform: skew(40deg);
  cursor: pointer;
  padding-top: 7px;
}

.MainMenuNavigation {
  /*height: 0px;
  width: 0%;*/
  width: 310px;
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 39px;
  animation-name: menuToHide;
  animation-duration: 1s;
  animation-timing-function: linear;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}

.MainMenuNavigationFirst {
  width: 310px;
  background-color: rgb(0, 108, 189);
  color: #fff;
  height: 39px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}

.MainMenuNavigationFirst .MainMenuNavContainer {
  opacity: 0;
  pointer-events: none;
}

.MainMenuNavigation .MainMenuNavContainer {
  animation-name: menuToNavHide;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  opacity: 0;
  height: 100%;
  pointer-events: none;
}

.MainMenuNavigationOpen {
  background-color: rgb(0, 108, 189);
  animation-name: menuToShow;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  height: 39px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}

.MainMenuNavigationOpen .MainMenuNavContainer {
  animation-name: menuToNavShow;
  animation-duration: 0.7s;
  animation-timing-function: linear;
  height: 100%;
  pointer-events: all;
}

@keyframes menuToHide {
  0% {
    width: 100%;
  }
  50% {
    width: 310px;
  }
  100% {
    width: 310px;
  }
}

@keyframes menuToShow {
  0% {
    width: 310px;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes menuToNavShow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menuToNavHide {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.NavContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;
  height: 100%;
}

.NavContainerItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  width: 15%;
  justify-content: center;
  color: #fff;
  transition: 0.5s;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  font-weight: 300 !important;
}

@media (max-width: 1615px){

.NavContainer {
  justify-content: flex-end;
}

}

@media (max-width: 1350px){

.NavContainer {
  justify-content: flex-end;
}

.NavContainerItem {
  width: 13%;
}

}

@media (max-width: 1000px){

.NavContainer {
  justify-content: flex-end;
}

.NavContainerItem {
  width: 12%;
}

}

.NavContainerItem:focus,
.NavContainerItem:active,
.NavContainerItem:hover {
  color: rgb(0, 108, 189);
  background-color: #fff;
}

.NavContainerItemIcon {
  padding-top: 7px;
}

/*  MEGA MENU*/

.DropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.DropdownContent .Header {
  background: red;
  padding: 16px;
  color: white;
}

.NavContainerItem:hover .DropdownContent {
  display: block;
}

.MegaMenu {
  background: transparent;
  width: 100%;
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  z-index: 10;
}

.MegaMenu .Hide {
  display: none;
  opacity: 0;
}

.MegaMenu .Visible {
  display: block;
  opacity: 1;
}

/*@keyframes megaMenuHiding {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 0;
    display: none;
  }
}*/

.MenuParent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

.MenuSectionLeft {
  background-color: #eeeeee;
  padding: 25px;
  flex-basis: 25%;
  border-bottom-left-radius: 5px;
}

.MenuSectionRight {
  background-color: #bbdefb;
  padding: 25px;
  flex-basis: 75%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  border-bottom-right-radius: 5px;
}

.MenuImage {
  height: 230px;
}

.MenuSectionRightLinks {
  flex-grow: 1;
}

.MenuSectionRightLinks table {
  min-width: 70%;
}

.MenuSectionRightLinks h6 {
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #212121;
  margin-top: 2px;
  margin-bottom: 0px;
}

.MenuSectionRightLinks td {
  font-size: 1rem;
  font-weight: 300;
  color: #212121;
  cursor: pointer;
  transition: 0.5s;
  line-height: 1.4;
  transition: transform 0.3s ease;
  transform: translateX(0px);
}

.MenuSectionRightLinks td.Divide {
  padding-top: 15px;
}

.MenuSectionRightLinks td:hover {
  color: rgb(0, 108, 189);
  transform: translateX(10px);
}

.MenuSectionRightImages {
  flex-grow: 0;
  min-width: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.MenuSectionLeftItem {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 5px 0px;
  color: #212121;
  cursor: pointer;
  transition: 0.5s;
  transition: transform 0.3s ease;
  transform: translateX(0px);
}

.MenuSectionLeftItem a {
  color: #212121;
}

.MenuSectionLeftItem:hover {
  transform: translateX(10px);
  color: rgb(0, 108, 189);
}

.MenuSectionLeftItem a:hover {
  color: rgb(0, 108, 189);
}

.MenuAktuellNachriten {
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 315px;
  font-size: 1.1rem !important;
}

.MenuAktuellNachriten::first-letter {
  font-weight: 400;
}

.HrLine {
  width: 75%;
  display: inline-block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(0, 108, 189, 0.2);
  margin-block-start: 2px;
  margin-block-end: 2px;
}
