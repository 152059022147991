.WetterSymbolLegendeParent {
    background-color: #fff;
    padding: 10px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: "Source Sans Pro", sans-serif !important;
    margin-bottom: 0.5rem;
  }
  
  .HeadSection {
    margin-bottom: 1.5rem;
  }
  
  .BreadcrubsLink {
    color: rgba(0, 62, 133, 0.6) !important;
    text-decoration: none !important;
    transition: 0.2s ease-in;
    font-size: 0.8rem;
    line-height: 1.5;
    font-family: "Source Sans Pro", sans-serif !important;
    cursor: pointer;
    font-weight: 00;
  }
  
  .BreadcrubsLink:hover,
  .BreadcrubsLink:focus,
  .BreadcrubsLink:active {
    color: rgba(0, 62, 133, 1) !important;
    text-decoration: none !important;
  }
  
  .BreadcrubsNonLink {
    font-size: 0.8rem;
    font-family: "Source Sans Pro", sans-serif !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
  }
  
  .DetailsSection {
    margin-bottom: 2rem;
  }
  
  .BtmSection {
    margin-top: 1.5rem;
  }

  /*.PollenflugKachelParent{
    background-color: #fff;
    padding: 15px 20px 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: .5rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}*/

.PollenflugItemsContainer .PollenflugItems{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PollenflugItemsContainer .PollenflugItems .PollenflugItemsRow{
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px;
    width: 25%;
}

.PollenflugItemsContainer .PollenflugItems .PollenflugItemsRow:first-of-type{
    border-left: 1px solid transparent;
    padding-left: 0px;
}

.PollenflugItemsContainer .ContainerHeading{
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #616161;
    font-weight: 300;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.PollenflugItemsContainer .PollenflugItemsColumn{
    display:  flex;
    flex-direction: row;
    margin-top: 5px;
}

.PollenflugItemsContainer .PollenflugItemsColumn .PollenflugValues{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.PollenflugItemsContainer .PollenflugName{
    margin-left: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: .9;
    color: #757575;
}

.PollenflugItemsContainer .PollenflugStrength{
    margin-left: 8px;
    color: rgb(0,62,133);
    font-size: .9rem;
    font-weight: 400 !important;
    line-height: 1.4;
}

.PollenflugItemsContainer .PollenflugItems .PollenflugIcon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.PollenflugItemsContainer .PollenflugItems .PollenflugIcon div{
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PollenflugItemsContainer .ButtonTags{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
}

.PollenflugItemsContainer .ButtonTags div {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 300;
    color: rgba(0,62,133,.6);
    cursor: pointer;
}

.PollenflugItemsContainer .ButtonTags div:hover,
.PollenflugItemsContainer .ButtonTags div:active,
.PollenflugItemsContainer .ButtonTags div:focus {
    color: rgba(0, 0, 0, 0.6);
}


.PollenflugItemsContainer .ButtonTags div.ActiveTab{
    color: rgba(0, 0, 0, 0.6);
}

.PollenflugItemsContainer .ButtonTags div:nth-child(even) {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
}

.PollenflugItemsContainer .ButtonTags div:first-of-type{
    padding-left: 0px;
}

.Loader {
  border: 2px solid #ebebeb;
  border-radius: 50%;
  border-top: 2px solid rgb(0,62,133);
  border-bottom: 2px solid rgb(0,62,133);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: inline-block;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}