.BiowetterItems{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.BiowetterItems .BiowetterItemsRow{
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px;
    width: 25%;
}

.BiowetterItems .BiowetterItemsRow:first-of-type{
    border-left: 1px solid transparent;
    padding-left: 0px;
}

.BiowetterItemsColumn{
    display:  flex;
    flex-direction: row;
    margin-top: 5px;
}

.BiowetterItemsColumn .BiowetterValues{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.BiowetterItems .BiowetterIcon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.BiowetterItems .BiowetterIcon div{
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.BiowetterItemsColumn .BiowetterValues{
    display:  flex;
    flex-direction: column;
    justify-content: center;
}

.BiowetterName{
    margin-left: 8px;
    font-size: 1rem;
    color: #757575;
    font-weight: 400;
    line-height: .9;
}

.BiowetterStrength{
    margin-left: 8px;
    color: rgb(0,62,133);
    font-size: .9rem;
    font-weight: 400 !important;
    line-height: 1.4;
}