

.Navigation{
    margin-top: 1rem;
    min-height: 50vh;
}

.Aside{
    width: 100%;
}
