.FooterTParent{
    background-color: #fff;
    padding: 15px 20px 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: .7rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}

.FooterBParent{
    margin-top: .7rem;
    background-color: rgb(0, 108, 189);
    color: #fff;
    padding: 15px 20px 20px;
    font-family: 'Source Sans Pro', sans-serif !important;
    position: relative;
}

@media (min-width: 950px) and (max-width: 1750px){
    .FooterBParent{
        margin-top: 3.3rem;
    }
}
