.AnzigeParent{
    width: 100%;
    height: auto;
    /*border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;*/

    
}

.VertilaleWerbung
{
    margin-bottom: .5rem;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 3px;
    padding: 10rem 1rem;
    color: rgb(84,84,84);
    text-align: center;
    background: rgba(255, 255, 255, .2);
}

.HorizontaleWerbung{
    margin-top: .4rem;
    border: 1px solid rgba(255, 255, 255, .3);
    border-radius: 3px;
    padding: 5rem 1rem;
    color: rgb(84,84,84);
    text-align: center;
    background: rgba(255, 255, 255, .5);
}