.NoTokenParent{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #d32f2f;
}

.ErsteLadung{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
}

.LocationLoader {
border: 7px solid transparent;
border-radius: 50%;
border-top: 7px solid rgb(0,62,133);
border-bottom: 7px solid rgb(0,62,133);
width: 30px;
height: 30px;
-webkit-animation: spin 1s linear infinite;
animation: spin 1s linear infinite;
z-index:999
}

@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.LinearActivity {
  overflow: hidden;
  width: 100%;
  height: 3px;
  background-color: #B3E5FC;
  margin: 0px auto;
}

.Indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.Indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #03A9F4;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.Indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #4FC3F7;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

.LocationLoader {
  border: 10px solid #EEEEEE;
  border-radius: 50%;
  border-top: 10px solid rgb(0,62,133);
  border-bottom: 10px solid rgb(0,62,133);
  width: 75px;
  height: 75px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
  z-index:999
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}