@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Exo 2', sans-serif !important;
  background-color: #fff;
  color: rgb(84,84,84);
  outline: none;
  background-color: #FAFAFA;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a{
  text-decoration: none !important;
}

.boxStyle{
  background-color: #ffffff;
  border-radius: 3px;
  border: 0px solid #EEEEEE;
  margin-top: 10px;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleDark{
  background-color: #252525;
  border-radius: 3px;
  border: 0px solid #252525;
  margin-top: 10px;
}

.boxStyleWithPadding{
  background-color: #ffffff;
  border-radius: 3px;
  border: 0px solid #EEEEEE;
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleWithPadding-dark{
  background-color: #252525;
  border-radius: 3px;
  border: 0px solid #252525;
  margin-top: 10px;
  padding: 10px;
}

.boxStyleWithPaddingSpecial{
  background-color: #ef5350;
  border-radius: 3px;
  border: 1px solid #ef5350;
  padding: 0px;
  /*box-shadow: rgba(60, 64, 67, 0.04) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;*/
  color: #fff;
}

.boxStyleWithPaddingSpecial-dark{
  background-color: #ef5350;
  border-radius: 3px;
  border: 1px solid #ef5350;
  color: #3eb8ff;
  padding: 0px;
  color: #fff;
}

.boxStyleWithPaddingSpecial-dark .MuiTypography-colorTextSecondary{
  color: #fff !important;
}

.boxStyleWithPaddingSpecial .warningParent .MuiList-padding{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial .warningParent .MuiListItem-dense{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial .warningParent .MuiListItem-gutters{
  padding-left: 10px;
  padding-right: 10px;
}

.boxStyleWithPaddingSpecial .warningParent .MuiAvatar-colorDefault{
  background-color: rgba(245,245,245 ,1);
}

.boxStyleWithPaddingSpecial .warningParent .MuiListItemText-secondary{
  font-weight: 300;
  color: #fff !important;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiList-padding{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiListItem-dense{
  padding-top: 0px;
  padding-bottom: 0px;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiListItem-gutters{
  padding-left: 10px;
  padding-right: 10px;
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiAvatar-colorDefault{
  background-color: rgba(245,245,245 ,1);
}

.boxStyleWithPaddingSpecial-dark .warningParent .MuiListItemText-secondary{
  color: rgba(245,245,245 ,1);
  font-weight: 400;
}

.warningContainer .MuiBox-root{
  padding: 0px 10px 0px;
}

.boxStyleWithPaddingSecondary{
  background-color: rgb(0,142,223);
  border-radius: 3px;
  border: 0px solid rgb(0,142,223);
  margin-top: 0px;
  padding: 10px;
  color: #fafafa;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleWithPaddingSecondaryDark{
  background-color: #06599f;
  border-radius: 3px;
  border: 0px solid #06599f;
  margin-top: 0px;
  padding: 10px;
  color: #d1d1d1;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleP-NMT{
  background-color: #ffffff;
  border-radius: 3px;
  border: 0px solid #EEEEEE;
  margin-top: 0px;
  padding: 10px;
  box-shadow: rgba(60, 64, 67, 0.01) 0px 1px 2px 0px, rgba(60, 64, 67, 0.01) 0px 1px 3px 1px;
}

.boxStyleP-NMT-Dark{
  background-color: #252525;
  border-radius: 3px;
  border: 0px solid #252525;
  margin-top: 0px;
  padding: 10px;
}

.invert
{
  background-color: rgb(0,142,223);
  border: 1px solid rgb(0,142,223);
  color: #ffffff;
}

.invertDark
{
  background-color: #06599f;
  border: 1px solid #06599f;
  color: #ffffff;
}

.rec-dot
{
    background-color: rgba(84,84,84, .4) !important;
    height: 5px !important;
    width: 5px !important;
    box-shadow: none !important;
    margin-top: 0px !important;
}

.boxStyleP-NMT-Dark .rec-dot
{
    background-color: rgba(107, 107, 107, 0.4) !important;
    height: 5px !important;
    width: 5px !important;
    box-shadow: none !important;
    margin-top: 0px !important;
}

.boxStyleP-NMT-Dark .rec-dot_active
{
  background-color: #06599f !important;
  height: 8px !important;
  width: 8px !important;
}

.rec-dot_active
{
  background-color: rgba(0,142,223, 1) !important;
  height: 8px !important;
  width: 8px !important;
}

.invert .rec-dot{
  background-color: rgba(255,255,255, .3) !important;
}

.invert .rec-dot_active{
  background-color: #ffffff !important;
}

.rec-pagination
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 10px;
  margin-top: 5px !important;
}

.rec-slider-container{
  margin: 0 !important;
}

.dialog-confirm-btn
{
  background-color: rgb(0,62,133);
  color: #fff;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
  outline: none !important;
}

.dialog-confirm-btn-dark
{
  background-color: #06599f;
  border-color: #06599f !important;
  color: #fff;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px inset, rgb(165 202 234) 0px 0px 0px !important;
  outline: none !important;
}

.MuiTab-textColorPrimary.Mui-selected{
    background-color: rgb(0,142,223);
    color: #fafafa !important;
}

.darkHead{
  background-color: #1e1e1e;
}

.darkHead .MuiTab-textColorPrimary{
  color: #d1d1d1 !important;
}

.darkHead .MuiTab-textColorPrimary.Mui-selected{
    background-color: #06599f;
    color: #d1d1d1 !important;
}

#titlesvgshadow {
  text-shadow: 0px 0px 1px #fff;
}

#titlesvgshadowDark {
  text-shadow: 0px 0px 0px #616161;
}

.leaflet-control-layers-expanded{
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: .8rem;
  font-weight: normal;
  }
  

  .VierzehnTagesprognodeChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:last-child,
  .VierzehnTagesprognodeChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:nth-last-child(2),
  .TagesverlaufChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:last-child,
  .TagesverlaufChart .recharts-cartesian-grid .recharts-cartesian-grid-vertical line:nth-last-child(2){
    display: none;
  }

  .NoPage{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
  }
  .boxStyleWithPadding-dark .htmlParser p,
  .boxStyleWithPadding-dark .htmlParser h1,
  .boxStyleWithPadding-dark .htmlParser h2,
  .boxStyleWithPadding-dark .htmlParser h3,
  .boxStyleWithPadding-dark .htmlParser h4,
  .boxStyleWithPadding-dark .htmlParser h5,
  .boxStyleWithPadding-dark .htmlParser h6,
  .boxStyleWithPadding-dark .htmlParser ul,
  .boxStyleWithPadding-dark .htmlParser ol{
    color: #d1d1d1 !important;
  }

  .boxStyleWithPadding-dark .htmlParser a,
  .boxStyleWithPadding-dark .htmlParser a span{
    color:rgba(6,89,159,1) !important;
  }

  .boxStyleWithPadding-dark .htmlParser span{
    color: #d1d1d1 !important;
    background-color: transparent !important;
  }

  .boxStyleWithPadding-dark .htmlParser figure.table{
    color: #d1d1d1 !important;
    background-color: transparent !important;
  }

  .htmlParser figure table{
    border-collapse: collapse !important;
  }

  .htmlParser figure table,
  .htmlParser figure.table td,
  .htmlParser figure.table th{
    border: 1px solid #ccc !important;
    padding: 5px;
  }

  .htmlParser figure.table{
    overflow-x: auto;
    margin-left: 0px;
    margin-right: 0px;
  }